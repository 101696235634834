import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import close from '../../resources/images/close.png';
import right from '../../resources/images/rightWhite.png';
import more from '../../resources/images/more.png';
import less from '../../resources/images/less.png';
import cupom from '../../resources/images/cupom.png';
import remove from '../../resources/images/remove.jpg';
import logo from '../../resources/images/logotipo-preto.png';
import semProdutos from '../../resources/images/sem_produtos.jpg';
import carregamento from '../../resources/images/loading_white.gif';
import Axios from 'axios';

export default function Carrinho() {
    const history = useHistory();
    const [produtos, setProdutos] = useState([]);
    const [carregar, setCarregar] = useState(null);
    const [order, setOrder] = useState(null);
    const [cupomDesconto, setCupomDesconto] = useState([]);
    const [totalPedido, setTotalPedido] = useState(null);
    const [itensPedido, setItensPedido] = useState(null);
    const [observations, setObservations] = useState([]);
    const [cupomTrue, setCupomTrue] = useState(null);
    const [cupomDescontoValor, setCupomDescontoValor] = useState(null);
    const [shopkeeper, setShopkeeper] = useState(null);
    const [taxaServico, setTaxaServico] = useState(null);
    const [loading, setLoading] = useState(true);

    const diminuiQuantidade = async (quantity, item) => {
        if (quantity <= 0) {
            return false
        }
        setCarregar(true);
        let array = produtos;
        array[item].amount = parseInt(array[item].amount) - 1;
        try {
            let atualizar = {
                id_stock_list_product: array[item].id_stock_list_product,
                shopkeeper: localStorage.getItem('shopkeeper'),
                mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
                action: 2,
                amount: 1
            }
            let {data} = await Axios.post('order/cart', atualizar);
            let storeId = getParamFromUrl('shopkeeper');
            if (storeId) {
                await atualizaPedidoStatus(storeId);
            }
            history.push(`/carrinho?shopkeeper=` + localStorage.getItem('shopkeeper'));
            setCarregar(false);
        } catch (err) {
            setCarregar(false);
            alert('Ops! ' + err);
            console.log(err);
        }
    };
    const aumentaQuantidade = async (quantity, item) => {
        setCarregar(true);
        let array = produtos;
        if (array[item].stock != false) {
            if (quantity <= array[item].amount) {
                alert('Você não pode pedir mais que ' + quantity + ' itens')
                setCarregar(false);
                return true
            }
        }
        array[item].amount = parseInt(array[item].amount) + 1;
        try {
            let atualizar = {
                id_stock_list_product: array[item].id_stock_list_product,
                shopkeeper: localStorage.getItem('shopkeeper'),
                mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
                action: 1,
                amount: 1
            }
            let {data} = await Axios.post('order/cart', atualizar);
            setCarregar(false);
            let storeId = getParamFromUrl('shopkeeper');
            if (storeId) {
                atualizaPedidoStatus(storeId);
            }
            history.push(`/carrinho?shopkeeper=` + localStorage.getItem('shopkeeper'));
        } catch (err) {
            setCarregar(false);
            alert('Ops! ' + err);
            console.log(err);
        }
    };

    const getParamFromUrl = (param = "") => {
        const url = new URL(window.location.href);
        return url.searchParams.get(param)
    };

    const atualizaPedidoStatus = async (item) => {
        try {
            let {data} = await Axios.get('order/cart-open?shopkeeper=' + item);
            setProdutos(data.items);
            setTotalPedido(data.total_price_br);
            setItensPedido(data.total_itens);
            setCupomTrue(data.cupom_order);
            setCupomDescontoValor(data.total_price_desconto);
        } catch (err) {
            setCarregar(false);
            alert('Ops! Você não possui mais nenhum item no carrinho!');
            window.location.href = `/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`;
            console.log(err);
        }
    }

    const getPedido = async (item) => {
        let {data} = await Axios.get('order/cart-open?shopkeeper=' + item);
        setProdutos(data.items);
        setLoading(false);
        setTotalPedido(data.total_price_br);
        setItensPedido(data.total_itens);
        setObservations(data.observations);
        setOrder(data.order_id);
        setCupomDesconto(data.cupom);
        localStorage.setItem('id_carrinho', data.order_id);
        setCupomTrue(data.cupom_order);
        setCupomDescontoValor(data.total_price_desconto);
        setShopkeeper(data.shopkeeper);
        setTaxaServico(data.apos_service_fee_value);
    }

    const removeItem = async (a) => {
        try {
            let atualizar = {
                id_observation: a.id,
                text_observation: a.observation,
            }
            let {data} = await Axios.post('order/observation-delete', atualizar);
            setCarregar(false);
            window.location.href = `/carrinho?shopkeeper=` + localStorage.getItem('shopkeeper');
        } catch (err) {
            setCarregar(false);
            alert('Ops! ' + err);
            console.log(err);
        }
    }

    const finalizaPedido = async () => {
        try {
            localStorage.setItem('pedido_id', order);
            history.push("/carrinho/cartao");
        } catch (err) {
            alert('Ops, ' + err);
            console.log(err);
        }
    }

    const removeCupom = async (codigo, id) => {
        setCarregar(true);
        try {
            let atualizar = {
                cupom: codigo,
                cupom_id: id,
                order: localStorage.getItem('id_carrinho')
            }
            let data = await Axios.post('order/cupon-remove', atualizar);
            setCarregar(false);
            setCupomDesconto(false);
            let storeId = getParamFromUrl('shopkeeper');
            if (storeId) {
                atualizaPedidoStatus(storeId);
            }
            window.location.href = `/carrinho?shopkeeper=` + localStorage.getItem('shopkeeper');
        } catch (err) {
            setCarregar(false);
            alert('Ops! ' + err);
            console.log(err);
        }
    }

    const loadContent = async () => {
        try {
            const storeId = getParamFromUrl('shopkeeper');
            if (storeId) {
                await getPedido(storeId);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            console.log(err.response);
        }
    }

    useEffect(() => {
        loadContent();
    }, []);

    useEffect(() => {
    }, [produtos, itensPedido, totalPedido, cupomDesconto]);

    const additionalTitle = {
        fontSize: 14,
        marginTop: 15,
        fontWeight: "bold",
    };

    return (
        loading == false ? (
            <div className="content carrinho pb_buttons_fixed">
                {observations && observations.length >= 1 ? (
                    <div className="title title_bold">
                        Observações
                    </div>
                ) : (null)}
                <div className="observacoes">
                    <ul>
                        {observations && observations.map((item, index) => (
                            <li key={index}>
                                <div className="texto">
                                    {item.observation}
                                </div>
                                <div className="button" onClick={() => removeItem(item)}>
                                    <img src={close} alt="Remover"/>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {cupomDesconto != null ? (
                    <div className="cupomArea">
                        <div className="cuponsTitle">
                            <img src={cupom} alt="cupom"/>
                            <div>
                                <div>
                                    {cupomDesconto && cupomDesconto.code}
                                </div>
                                {cupomDesconto.value != undefined && (
                                    <div
                                        className="cupomBold">{cupomDesconto.value && cupomDesconto.type === 'Porcentagem' ? cupomDesconto.value + '%' : 'R$ ' + cupomDesconto.value}</div>
                                )}
                            </div>
                        </div>
                        <div className="removerCupom" onClick={() => removeCupom(cupomDesconto.code, cupomDesconto.id)}>
                            <img src={remove} alt="remover cupom"/>
                        </div>
                    </div>
                ) : (
                    <Link to={'/carrinho/cupom'} className="cupomArea">
                        <div className="cuponsTitle">
                            <img src={cupom} alt="cupom"/>
                            <div>
                                Adicionar um cupom
                            </div>
                        </div>
                    </Link>
                )}
                {produtos && produtos.length >= 1 && loading == false ? (
                    <div className="row_pedido">
                        <div className="title title_bold">
                            Pedido
                        </div>
                        <div className="more_back"
                             onClick={() => window.location.href = `/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`}>
                            {/* <img src={more} alt="Mais" /> */}
                            Adicionar itens
                        </div>
                    </div>
                ) : (
                    <div className="title title_bold">
                        Nenhum produto adicionado :(
                    </div>
                )}
                <div className="pedidoListaArea">
                    <ul>
                        {produtos.map((prod, index) => (
                            <li style={{display:"flex", flexDirection:"column", padding: '1rem', backgroundColor: '#F8F8F8', borderRadius: 8}} key={index}>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    <div className="photoarea">
                                        <div className={prod.image ? 'image' : 'imageCover'}
                                             style={{backgroundImage: "url(" + (prod.image ? prod.image : semProdutos) + ")"}}></div>
                                    </div>
                                    <div className="productInfo">
                                        <div className="productName">
                                            {prod.name}
                                        </div>
                                        <div className="moreLessArea">
                                            <div className="somaDiminui">
                                                <button className="less"
                                                        onClick={() => diminuiQuantidade(prod.amount, index)}
                                                        disabled={carregar}>
                                                    <img src={less} alt="menos"/>
                                                </button>
                                                <div className="quantidadeMorearea">
                                                    <div className="quantidade">
                                                        {prod.amount}
                                                    </div>
                                                    {prod.additionals.length <= 0 && (
                                                    <button className="more"
                                                            onClick={() => aumentaQuantidade(prod.quantity, index)}
                                                            disabled={carregar}>
                                                        <img src={more} alt="mais"/>
                                                    </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="valorProduto">
                                                {prod.cupom && (
                                                    <div className="valorDesconto">R$ {prod.price_original}</div>
                                                )}
                                                R$ {prod.total_price_br}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {prod.additionals.length > 0 && (
                                    <ul>
                                        <li>
                                            <div style={additionalTitle}>Adicionais</div>
                                        </li>
                                        {prod.additionals.map((additional, index) => (
                                            <li  style={{fontSize:12}}>
                                                <div style={{display:"flex", flexDirection:"row", width:'100%', justifyContent:"space-between"}}>
                                                    <div>
                                                        {additional.quantity} - {additional.name} (R$ {additional.price})
                                                    </div>
                                                    <div>
                                                        R$ {additional.amount}
                                                    </div>
                                                </div>
                                            </li>

                                        ))}
                                    </ul>
                                )}

                            </li>
                        ))}
                    </ul>
                </div>

                {shopkeeper && shopkeeper.mv_shopkeepers_use_service_charge && taxaServico && (
                    <div className="totalTaxaServico" >
                        <div className="title leftTitle">
                            Taxa de Serviço:
                        </div>

                        <div>
                            <div className="title rightTitle">
                                R$ {taxaServico}
                            </div>
                        </div>
                    </div>
                )}

                {totalPedido && (
                    <div className="areaSelected">
                        {/* estas divs abaixo deverão aparecer após o usuário selecionar algum item */}
                        <div className="spaceFixed"></div>
                        <div className="areaSelectedBox" onClick={() => finalizaPedido()}>
                            <div className="quantArea">
                                {itensPedido}
                            </div>
                            {cupomTrue ? (
                                <div className="valueArea">
                                    <div className="valorDescontado">
                                        R$ {totalPedido}
                                    </div>
                                    R$ {cupomDescontoValor}
                                </div>
                            ) : (
                                <div className="valueArea">
                                    R$ {totalPedido}
                                </div>
                            )}
                            <div className="nextArea">
                                <img src={right} alt="avançar"/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className="content detalheProduto">
                <div className="loading_itens">
                    <img src={carregamento} alt="loading" className="loadingGif"/>
                </div>
            </div>
        )
    )
}