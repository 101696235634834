import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import carregamento from '../../resources/images/loading_white.gif';
export default function Notifications() {
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(true);
  const getNotifications = async () => {
    try {
      let {data} = await Axios.get('notifications');
      setNotification(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const loadContent = async () => {
    await getNotifications();
  }
  
  useEffect(() => {
    loadContent();
  }, []);
  return (
    loading == false ? (
    <div className="content notifications">
      {notification.length > 0 ? (
      <ul>
        {notification.map((item, index) => (
          <li key={index}>
            <Link to={item.route}>
            {item.image ? (
              <div className="imageArea">
                <div className="image" style={{backgroundImage: "url(" + item.image + ")"}}></div>
              </div>
               ) : (null)}
              <div className={item.image ? 'text' : 'text_full'} dangerouslySetInnerHTML={{ __html: item.message }} />
            </Link>
          </li>
        ))}
      </ul>
      ) : (
        <div className="title title_bold">
          Nenhuma notificação :(
        </div>
      )}
    </div>
    ):(
      <div className="content detalheProduto">
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      </div>
    )
  )
}
