import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import facebook from '../../resources/images/facebook.png';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
// import FacebookLogin from 'react-facebook-login';
import OneSignal from 'react-onesignal';
export default function Login() {
  const formLogin = useRef(null);
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const history = useHistory();
  const [login, setLogin] = useState({
    name: '',
    email: '',
    telefone: '',
    birth: '',
    password: '',
    zipcode: '',
    state: '',
    city: '',
    cpf: '',
    passport: '',
    document: 'CPF',
    passwordRepeat: ''
  });

  const getEstados = async () => {
    let { data } = await axios.get('states');
    setEstados(data);
  }

  const getCidades = async (state) => {
    let resultado = estados.filter((item) => item.uf_initials === state);
    if (resultado.length) {
      let { data } = await axios.get('uf-cities/uf/' + resultado[0].uf_code);
      setCidades(data);
      return data
    }
    return []
  }

  const getFacebookData = () => {
    const user = JSON.parse(localStorage.getItem('userFacebook'))

    setLogin({
      ...login,
      ...user
    })
  };

  const phone = (input) => {
    if (input && input.length >= 14) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }

  const birth = (input) => {
    return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  }

  const cpf = (input) => {
    return [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  }

  const cep = (input) => {
    return [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  }

  const verificaCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9, 10].forEach(function (j) {
      var soma = 0, r;
      cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
        soma += parseInt(e) * ((j + 2) - (i + 1));
      });
      r = soma % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r != cpf.substring(j, j + 1)) result = false;
    });
    return result;
  }

  const checkCep = async (e) => {
    if (e.target.value.length >= 9) {
      let cepPreenchido = e.target.value;
      try {
        let retorno = await axios.get('https://viacep.com.br/ws/' + e.target.value + '/json');
        let estado = '';
        let noEstado = false;
        switch (retorno.data.uf) {
          case 'AC':
            estado = 'Acre';
            break;
          case 'AL':
            estado = 'Alagoas';
            break;
          case 'AP':
            estado = 'Amapá';
            break;
          case 'AM':
            estado = 'Amazonas';
            break;
          case 'BA':
            estado = 'Bahia';
            break;
          case 'CE':
            estado = 'Ceará';
            break;
          case 'DF':
            estado = 'Distrito Federal';
            break;
          case 'ES':
            estado = 'Espírito Santo';
            break;
          case 'GO':
            estado = 'Goiás';
            break;
          case 'MA':
            estado = 'Maranhão';
            break;
          case 'MT':
            estado = 'Mato Grosso';
            break;
          case 'MS':
            estado = 'Mato Grosso do Sul';
            break;
          case 'MG':
            estado = 'Minas Gerais';
            break;
          case 'PA':
            estado = 'Pará';
            break;
          case 'PB':
            estado = 'Paraíba';
            break;
          case 'PR':
            estado = 'Paraná';
            break;
          case 'PE':
            estado = 'Pernambuco';
            break;
          case 'PI':
            estado = 'Piauí';
            break;
          case 'RJ':
            estado = 'Rio de Janeiro';
            break;
          case 'RN':
            estado = 'Rio Grande do Norte';
            break;
          case 'RS':
            estado = 'Rio Grande do Sul';
            break;
          case 'RO':
            estado = 'Rondônia';
            break;
          case 'RR':
            estado = 'Roraima';
            break;
          case 'SC':
            estado = 'Santa Catarina';
            break;
          case 'SP':
            estado = 'São Paulo';
            break;
          case 'SE':
            estado = 'Sergipe';
            break;
          case 'TO':
            estado = 'Tocantins';
            break
          default:
            noEstado = true;
        }
        if (!noEstado) {
          let cities = await getCidades(estado);
          let retornoCities = '';
          if (cities.length) {
            retornoCities = cities.filter((item) => item.city_name === retorno.data.localidade);
          }
          setLogin({
            ...login,
            state: estado,
            city: retornoCities.length ? retornoCities[0].city_name : '',
            zipcode: retorno.data.cep,
            street: retorno.data.logradouro,
            neighborhood: retorno.data.bairro
          })
        } else {
          setLogin({
            ...login,
            zipcode: cepPreenchido
          })
        }
      } catch (err) {
        setLogin({
          ...login,
          zipcode: cepPreenchido
        })
        console.log(err);
      }
    }
  }

  const logar = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      let phone_unmask = login.telefone;
      let birth_unmask = login.birth;

      if (login.password.length < 6) {
        alert('A senha precisa ter no mínimo 6 caracteres!')
        setLoading(false);
        return false
      }
      if (login.password !== login.passwordRepeat) {
        alert('O campo senha e repita a senha devem ser iguais!')
        setLoading(false);
        return false
      }
      if (login.name === '') {
        alert('Preencha seu nome')
        setLoading(false);
        return false
      }
      if (login.email === '') {
        alert('Preencha seu e-mail')
        setLoading(false);
        return false
      }
      if (login.telefone === '') {
        alert('Preencha seu telefone')
        setLoading(false);
        return false
      } else {
        phone_unmask = parseInt(login.telefone.replace(/[^0-9]/g, ''));
        phone_unmask = '' + phone_unmask + '';
      }

      if(login.birth){
        let birth = login.birth.split('/');
        birth_unmask = birth[2] + '-' + birth[1] + '-' + birth[0];
      }

      if (login.state === '') {
        alert('Preencha seu estado')
        setLoading(false);
        return false
      }

      if(login.document !== 'PASSPORT') {

        if (login.cpf === '') {
          alert('Preencha seu CPF')
          setLoading(false);
          return false
        }

        if (login.cpf) {
          let retorno = await verificaCpf(login.cpf)
          if (!retorno) {
            alert('Cpf inválido!')
            setLoading(false);
            return false
          }
        }
      } else {
        if (login.passport === '') {
          alert('Preencha seu Passaporte')
          setLoading(false);
          return false
        }
      }

      let nomes = login.name.trim().split(' ');
      if (nomes.length > 1) {
        for (var x = 0; x < nomes.length; x++) {
          if (nomes[x].match(/^[a-zA-Z\u00C0-\u017F´]*$/)) {
          } else {
            alert('Você precisa preencher nome e sobrenome!')
            setLoading(false);
            break;
            return false
          }
        }
      } else {
        alert('Você precisa preencher nome e sobrenome!')
        setLoading(false);
        return false
      }
      // if(login.name.match(/^[a-zA-Z\u00C0-\u017F´]+\s+[a-zA-Z\u00C0-\u017F´]{0,}$/) && login.name.trim().split(' ').length == 2) {
      //   console.log('nome válido')
      // } else {
      //   alert('Você precisa preencher nome e sobrenome!')
      //   setLoading(false);
      //   return false
      // }

      // estado: estados.filter((item) => item.uf_initials === login.state)[0].uf_code
      let send = {
        status: 1,
        name: login.name,
        cpf: login.cpf,
        email: login.email,
        phone: phone_unmask,
        birth: birth_unmask,
        zip_code: login.zipcode,
        state: login.state,
        city: login.city,
        password: login.password,
        passport: login.passport,
      }

      if (login.facebook_id) {
        send.facebook_id = login.facebook_id;
      }

      if (login.image) {
        send.image = login.image;
      }

      const { data } = await axios.post('customers/create', send)

      localStorage.setItem('userDados', JSON.stringify(data));
      axios.defaults.headers.common['Authorization'] = data.api_token;
      // const options = {allowLocalhostAsSecureOrigin: true, requiresUserPrivacyConsent: true}
      // OneSignal.initialize("23934880-54a9-40cd-9e43-ba34b7169dce", options);

      //comentado para teste local
      // OneSignal.sendTag("customer", data.id);

      // OneSignal.setExternalUserId(data.id);
      // window.location.href = "/cadastro/token";
      // abaixo comentado, caso usuario precise validar token
      // history.push("/cadastro/token");

      // adicao de não precisar mais adicionar token
      console.log(localStorage.getItem('userDados'));
      localStorage.setItem('user', localStorage.getItem('userDados'));
      if (localStorage.getItem('direcionar_carrinho')) {
        window.location.href = '/loja/add?&product_id=' + localStorage.getItem('direcionar_carrinho');
      } else {
        window.location.href = "/";
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert('Ops, ocorreu um erro ao efetuar o cadastro, o e-mail ou outro dado pessoal já foi utilizado!');
      console.log(err);
    }
  };

  const loadContent = async () => {
    await getEstados();
    getFacebookData();
  };

  useEffect(() => {
    loadContent();
  }, []);
  return (
    <div className="content login">
      <div className="loginTitle">
        Faça seu cadastro
      </div>
      <div className="loginText">
        Queremos te conhecer! Para isso precisamos de algumas informações
      </div>
      <form ref={formLogin} onSubmit={logar}>
        <div className="ui-input-title">
          Seu nome
        </div>
        <input
          value={login.name}
          onChange={(e) => setLogin({ ...login, name: e.target.value })}
          className="ui-input"
          placeholder="Nome"
          type="text"
          required
        />
        <div className="ui-input-title">
          Seu melhor e-mail
        </div>
        <input
          value={login.email}
          onChange={(e) => setLogin({ ...login, email: e.target.value })}
          className="ui-input"
          placeholder="E-mail"
          type="email"
          required
        />
        <div className="ui-input-title">
          Seu telefone
        </div>
        <MaskedInput
          value={login.telefone}
          onChange={(e) => setLogin({ ...login, telefone: e.target.value })}
          mask={phone}
          className="ui-input"
          placeholder="Telefone"
          guide={false}
        />
        <div className="ui-input-title">
          Seu Aniversário
        </div>
        <MaskedInput
          value={login.birth}
          onChange={(e) => setLogin({ ...login, birth: e.target.value })}
          mask={birth}
          className="ui-input"
          placeholder="Nascimento"
          guide={false}
        />
        <div className="ui-input-title">
          Documento oficial
        </div>
        <div style={{ margin: '30px' }} >
          <input
            checked={login.document === 'CPF'}
            name="document"
            id="cpf"
            onChange={(e) => setLogin({ ...login, document: 'CPF' })}
            type="radio"
            required
          />
          <label for="cpf">CPF</label>
          <input
            style={{ marginLeft: '130px' }}
            checked={login.document === 'PASSPORT'}
            name="document"
            id="passport"
            onChange={(e) => setLogin({ ...login, document: 'PASSPORT' })}
            type="radio"
            required
          />
          <label for="passport">Passaporte</label>
        </div>
        {login.document && login.document === 'PASSPORT' &&
          <Fragment>
            <div className="ui-input-title">
              Seu Passaporte
            </div>
            <input
              value={login.passport}
              onChange={(e) => setLogin({ ...login, passport: e.target.value })}
              className="ui-input"
              placeholder="Passaporte"
              type="text"
              required
            />
          </Fragment>}
        {login.document && login.document === 'CPF' &&
          <Fragment>
            <div className="ui-input-title">
              Seu CPF
            </div>
            <MaskedInput
              value={login.cpf}
              onChange={(e) => setLogin({ ...login, cpf: e.target.value })}
              mask={cpf}
              className="ui-input"
              placeholder="CPF"
              guide={false}
              required
            />
          </Fragment>
        }
        <div className="ui-input-title">
          Seu CEP
        </div>
        <MaskedInput
          value={login.zipcode}
          onChange={(e) => checkCep(e)}
          mask={cep}
          required
          className="ui-input"
          placeholder="CEP"
          guide={false}
        />
        <div className="ui-input-title">
          Seu Estado
        </div>
        <select
          value={login.state}
          onChange={(e) => {
            setLogin({ ...login, state: e.target.value })
            getCidades(e.target.value)
          }}
          required
          className="ui-input">
          <option value="" disabled>Selecione um estado</option>
          {estados.map((item, index) => (
            <option key={index} value={item.uf_initials}>{item.uf_initials}</option>
          ))}
        </select>
        <div className="ui-input-title">
          Sua Cidade
        </div>
        <select
          value={login.city}
          required
          onChange={(e) => setLogin({ ...login, city: e.target.value })}
          className="ui-input">
          <option value="" disabled>Selecione uma cidade</option>
          {cidades.map((item, index) => (
            <option key={index} value={item.city_name}>{item.city_name}</option>
          ))}
        </select>
        <div className="ui-input-title">
          Sua senha
        </div>
        <input
          value={login.password}
          onChange={(e) => setLogin({ ...login, password: e.target.value })}
          className="ui-input"
          placeholder="Senha"
          type="password"
          required
        />
        <div className="ui-input-title">
          Repita sua senha
        </div>
        <input
          value={login.passwordRepeat}
          onChange={(e) => setLogin({ ...login, passwordRepeat: e.target.value })}
          className="ui-input"
          placeholder="Repita a senha"
          type="password"
          required
        />
        <label style={{ margin: '10px' }}>
          <input
            name="TermosLGPD"
            type="checkbox"
            required
            style={{ margin: '10px' }} />
          Concordo com a&nbsp;
          <a href="https://yuzer.com.br/site/privacidade/" target="_balnk" style={{ color: '#6c6c87' }}>
            Política de Proteção de Dados Pessoais e da Privacidade da Yuzer
          </a>
        </label>
        <div className="buttonArea" style={{ marginTop: '20px' }}>
          <div className="hasAccount">
            <Link to="/login">
              Já tenho conta
            </Link>
          </div>
          <button type="submit" disabled={loading}>
            Cadastrar
          </button>
        </div>
      </form>
      {/* <div className="otherOption">
        Ou entre com
      </div> */}
      {/* <div className="loginButtons">
        <button type="button" className="facebook">
          <img src={facebook} alt="Facebook" />
          Facebook
        </button>
      </div> */}
    </div>
  )
}
