import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonNext, DotGroup } from 'pure-react-carousel';
import { Link } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import introOne from '../../resources/images/inicial.png';
import introTwo from '../../resources/images/secundaria.png';
export default function Intro() {
  return (
    <div className="sliderArea">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={window.innerHeight}
        totalSlides={2}
      >
        <Slider>
          <Slide index={0}>
            <div className="centerSlider">
              <img src={introOne} alt="intro" />
              <div className="title">
                Seja bem-vindo(a) !!
              </div>
              <div className="text">
                Esse é seu primeiro contato com o Menu Virtual. Será uma experiência incrível!
              </div>
              <Link to={'/qrcode'} className="pularHome">
                Pular
              </Link>
              <DotGroup className="dotsArea" />
              <ButtonNext className="NextButton">Próximo</ButtonNext>
            </div>
          </Slide>
          <Slide index={1}>
            <div className="centerSlider">
              <img src={introTwo} alt="intro" />
              <div className="title">
                Título 2
              </div>
              <div className="text">
                Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos.
              </div>
              <DotGroup className="dotsArea" />
              <Link to={'/qrcode'} className="prontoHome">
                Pronto!
              </Link>
            </div>
          </Slide>
        </Slider>
      </CarouselProvider>
    </div>
  )
}
