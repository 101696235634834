import React, { useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import facebook from '../../resources/images/facebook.png';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import OneSignal from 'react-onesignal';

export default function Login({refreshUser}) {
  const formLogin = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    email: '',
    password: '',
  });

  const getParamFromUrl = async (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const responseFacebook = async (response) => {
    if(!response.id) {
      return false;
    }
    console.log(response);
    const user = {
      facebook_id: response.id,
      name: response.name,
      email: response.email,
      image: response.picture.data.url,
    }
  
    localStorage.setItem('userFacebook', JSON.stringify(user));

    try {
      const send = {
        facebook_id: user.facebook_id,
        email: user.email,
      }

      const { data } = await axios.post('customers/login', send);
      
      localStorage.setItem('user', JSON.stringify(data));
      axios.defaults.headers.common['Authorization'] = data.api_token;
      let id_produto = await getParamFromUrl('product');
      const options = {allowLocalhostAsSecureOrigin: true, requiresUserPrivacyConsent: true}
      // OneSignal.initialize("23934880-54a9-40cd-9e43-ba34b7169dce", options);
      // OneSignal.sendTag("customer", data.id);
      // OneSignal.setExternalUserId(data.id);
      await refreshUser();
      if(id_produto) {
        window.location.href = '/loja/add?&product_id='+id_produto;
        // history.push('/loja/add?&product_id='+id_produto)
      } else {
        // localStorage.setItem('userDados', JSON.stringify(data));
        // history.push(data.verified == 1 ? "/" : "/cadastro/token")
        window.location.href = data.verified == 1 ? "/" : "/cadastro/token";
      }
      
    } catch(err) {
      // window.location.href = "/cadastro";
      history.push("/cadastro")
      console.log(err.response)
    }
  }  
 
  const logar = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const send = {
        email: login.email,
        password: login.password
      }
      const { data } = await axios.post('customers/login', send)
      localStorage.setItem('user', JSON.stringify(data));
      axios.defaults.headers.common['Authorization'] = data.api_token;

      let id_produto = await getParamFromUrl('product');
      const options = {allowLocalhostAsSecureOrigin: true, requiresUserPrivacyConsent: true}
      // OneSignal.initialize("23934880-54a9-40cd-9e43-ba34b7169dce", options);

      // OneSignal.setExternalUserId(data.id);
      // OneSignal.sendTag("customer", data.id);
      await refreshUser();
      if(id_produto) {
        // history.push('/loja/add?&product_id='+id_produto)
        window.location.href = '/loja/add?&product_id='+id_produto;
      } else {
        localStorage.setItem('userDados', JSON.stringify(data));
        // history.push(data.verified == 1 ? "/" : "/cadastro/token")
        window.location.href = data.verified == 1 ? "/" : "/cadastro/token";
      }
      
      setLoading(false);
    } catch (err) {
      alert('Ops, o e-mail ou a senha estão incorretos!');
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="content login">
      <div className="loginTitle">
        Olá! Bem-vindo!
      </div>
      <div className="loginText">
        Para efetuar o login, preencha os campos abaixo:
      </div>
      <form ref={formLogin} onSubmit={logar}>
        <div className="ui-input-title">
          Seu e-mail
        </div>
        <input
          value={login.email}
          onChange={(e) => setLogin({ ...login, email: e.target.value })}
          className="ui-input" 
          placeholder="E-mail" 
          type="email" 
          required 
        />
        <div className="ui-input-title">
          Sua senha
        </div>
        <input
          value={login.password}
          onChange={(e) => setLogin({ ...login, password: e.target.value })}
          className="ui-input" 
          placeholder="Senha" 
          type="password" 
          required 
        />
        <div className="buttonArea">
          <div className="hasAccount">
            <Link to="/cadastro">
              Não tenho conta
            </Link>
          </div>
          <button type="submit" disabled={loading}>
            Login
          </button>
        </div>
      </form>
      {/*<div className="otherOption">*/}
      {/*  Ou entre com*/}
      {/*</div>*/}
      {/*<FacebookLogin*/}
      {/*  appId="890937268431096"*/}
      {/*  autoLoad={false}*/}
      {/*  fields="name,email,picture"*/}
      {/*  callback={responseFacebook}*/}
      {/*  redirectUri="https://menuvirtual.yuzer.com.br/login"*/}
      {/*  render={renderProps => (*/}
      {/*    <div className="loginButtons">*/}
      {/*      <button onClick={renderProps.onClick} type="button" className="facebook">*/}
      {/*        <img src={facebook} alt="Facebook" />*/}
      {/*        Facebook*/}
      {/*      </button>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*/>*/}
      <div className="forgotPassword">
        <Link to="/esqueci-a-senha">
          Esqueci a senha
        </Link>
      </div>
    </div>
  )
}
