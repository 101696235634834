import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import produto from '../../resources/images/produto.jpg';
import logo from '../../resources/images/logotipo-preto.png';
import semProdutos from '../../resources/images/sem_produtos.jpg';
import Axios from 'axios';
import Search from '../../resources/images/menu/search.png';

export default function LojasList() {
  const [categories, setCategories] = useState([]);
  const [categoria, setCategoria] = useState(null);
  const [search, setSearch] = useState('');

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const searchStore = async (e) => {
    e.preventDefault();
    if(!search.length) {
      return await loadContent();
    }
    let catUrl = categories.filter((item) => item.id == categoria);
    if(catUrl.length) {
      let produtos = catUrl[0].products.filter((item)=> item.name.toLowerCase().match(search.toLocaleLowerCase()));
      catUrl[0].products = produtos;
      return setCategories(
        catUrl
      )
    }
  }
  
  const loadContent = async () => {
    try {
      const qrcode = getParamFromUrl('qrcode');
      const storeId = getParamFromUrl('id_mv_shopkeepers');
      
      let url = `webapp/list-products?qrcode=${qrcode}`;
      if (storeId) {
        url += `&id_mv_shopkeepers=${storeId}`;
        //função verifica se há algum pedido em aberto
      }
      
      const { data } = await Axios.get(url);
      setCategories(data.products.category);
      setCategoria(getParamFromUrl('category_id'));

    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }
  useEffect(() => {
    loadContent();
  }, []);
  return (
    <div className="content produtosList">
      <div className="menuSearch busca_otop">
        <form onSubmit={searchStore}>
          <input type="search" placeholder="Digite aqui..." onChange={(e) => setSearch(e.target.value)} />
          <button type="submit">
            <img src={Search} alt="busca" />
          </button>
        </form>
      </div>
        {categories.map((cat,index) => (
        <ul key={index}>
          {
          cat.id == categoria && (
          cat.products.map((item,index) => (
          item.quantity > 0 | item.stock == false && (
          <li key={index}>
            <Link to={`/loja/add?&product_id=${item.id}`}>
              <div className="largura">
                <div className="boxProdutoImage">
                  <div className="image" style={{backgroundImage: "url(" + (item.image ? item.image : semProdutos) + ")"}}></div>
                </div>
                <div className="nome">
                  {item.name}
                </div>
                <div className="valor">
                  R$ {item.price_br}
                </div>
              </div>
            </Link>
          </li>
          ))))}
        </ul>
        ))}
    </div>
  )
}
