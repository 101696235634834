import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useHistory } from 'react-router-dom';
import checked from '../../resources/images/check.png';
import logo from '../../resources/images/logotipo-preto.png';
import right from '../../resources/images/rightWhite.png';
import Search from '../../resources/images/menu/search.png';
import cloneDeep from 'lodash/cloneDeep';
import Axios from 'axios';
import semProdutos from '../../resources/images/sem_produtos.jpg';
import carregamento from '../../resources/images/loading_white.gif';

export default function LojaHome() {
  const [categories, setCategories] = useState([]);
  const [totalPedido, setTotalPedido] = useState(null);
  const [itensPedido, setItensPedido] = useState(null);
  const [cupomTrue, setCupomTrue] = useState(null);
  const [loadSearch, setLoadSearch] = useState(true);
  const [cupomDescontoValor, setCupomDescontoValor] = useState(null);
  const [search, setSearch] = useState('');
  const [copyCategories, setCopyCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalBusca, setTotalBusca] = useState(1);
  const [banners, setBanners] = useState([]);

  const history = useHistory();
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 35
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 35
    }
  };
  const responsiveProduct = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 4
    }
  };
  const responsiveBebida = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 80
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 80
    }
  };

  
  const getBanners = async (shop, mv) => {
    try {
      let {data} = await Axios.get('banners/list?shop='+shop+'&mv='+mv);
      setBanners(data);
    } catch (err) {
      console.log(err);
    }
  }

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };
  
  const getPedido = async (item) => {
    if(localStorage.getItem('user') !== '{}' && localStorage.getItem('user') !== '' && localStorage.getItem('user') !== null) {
      let {data} = await Axios.get('order/cart-open?shopkeeper='+item);
      if(data) {
        setTotalPedido(data.total_price_br);
        setItensPedido(data.total_itens);
        setCupomTrue(data.cupom_order);
        setCupomDescontoValor(data.total_price_desconto);
      }
    }
  }
  const getPageUrl = async (a,id) => {
    // window.location.href = '/loja/produtos?qrcode=$'+getParamFromUrl('qrcode')+'&id_mv_shopkeepers='+getParamFromUrl('id_mv_shopkeepers')+'&category_id='+id+'&category_name='+a;
    history.push('/loja/produtos?qrcode='+getParamFromUrl('qrcode')+'&id_mv_shopkeepers='+getParamFromUrl('id_mv_shopkeepers')+'&category_id='+id+'&category_name='+a)
  };

  const limpaBusca = async () => {
    setSearch('')
    setTotalBusca(1)
    return await loadContent();
  }

  const searchStore = async (e) => {
    e.preventDefault();
    setLoadSearch(false)
    if(!search.length) {
      setLoadSearch(true)
      return await loadContent();
    }
    
    let newCategories = cloneDeep(copyCategories)
    let total = 0
    newCategories.map((categoria) => {
      let produtos = categoria.products.filter((item)=> item.name.toLowerCase().match(search.toLocaleLowerCase()));
      categoria.products = produtos;
      if(produtos.length > 0) {
        total = 1
      }
    });
    setTotalBusca(total)
    // console.log(total);
    setLoadSearch(true)
    setCategories(newCategories);

    setLoading(false);
    // setSearch('');
  }

  const loadContent = async () => {
    try {
      const qrcode = getParamFromUrl('qrcode');
      const storeId = getParamFromUrl('id_mv_shopkeepers');

      let qrcodeStorage = localStorage.getItem('code_qr');
      if(qrcodeStorage) {
        if(qrcodeStorage != qrcode) {
          return window.location.href= `/?qrcode=${qrcode}`
        }
      } else {
        return window.location.href= `/?qrcode=${qrcode}`
      }

      let user = JSON.parse(localStorage.getItem('user'));
      let url = '';
      if(user) {
        Axios.defaults.headers.common['Authorization'] = user.api_token;
        url = `webapp/list-products?qrcode=${qrcode}&user=${user.id}`;
      } else {
        url = `webapp/list-products?qrcode=${qrcode}`;
      }
      if (storeId) {
        url += `&id_mv_shopkeepers=${storeId}`;
      }
      let shop = localStorage.getItem('shopkeeper');
      localStorage.setItem('mv_shopkeeper', storeId);
      //função verifica se há algum pedido em aberto
      if(shop) {
        getPedido(shop);
      }

      const { data } = await Axios.get(url);
      localStorage.setItem('stock_list', data.shopkeeper.fk_id_stok_lists);
      //se for do tipo mv não tem pedido. se for shopkeeper, tem pedido
      let shopeek = localStorage.getItem('shopkeeper');
      if(!shopeek) {
        localStorage.setItem('shopkeeper', data.shopkeeper.fk_id_shopkeepers);
      }
      if(data.products.category != undefined) {
        setCategories(data.products.category);
        setCopyCategories(data.products.category);
      } else {
        setCategories(false);
      }
      
      if (storeId) {
        await getBanners(data.shopkeeper.id, data.mv.id);
      }

      setLoading(false);
    } catch(err) {
      setLoading(false);
      console.log(err);
      console.log(err.response);
    }
  }
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
  }, [categories]);

  return (
    <div className="content lojasArea">
      <div className="menuSearch busca_otop">
        <form onSubmit={searchStore}>
          <input type="search" placeholder="Digite aqui..." value={search} onChange={(e) => setSearch(e.target.value)} />
          <button type="submit">
            <img src={Search} alt="busca" />
          </button>
        </form>
      </div>
      {banners.length > 0 ?(
        <div className="destaquesSlider">
          <div className="homeTitle">
            Destaque
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={false}
            showDots={false}
            responsive={responsiveBebida}
            ssr={true} // means to render carousel on server-side.
            partialVisible={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={"mobile"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-space"
          >
            {banners.map((item, index) => (
              <div key={index} className="relativeItem">
                <div className="imageBig">
                <div className="imageBigItem" style={{backgroundImage: "url(" + (item.image_link ? item.image_link : semProdutos) + ")"}}></div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        ):('')}
      {totalBusca === 0 && (
        <div className="centerItens">
          <div className="title_bold">
            Nenhum produto encontrado.
          </div>
          <button className="buttonGo" onClick={() => limpaBusca()}>
            Ver todos os produtos
          </button>
        </div>
      )}
      {loadSearch && categories && categories.map((item, index) =>
        <div key={index}>
          { index == 0 || index == 2 ?
            //   <div className="divideTitle">
            //     <div className="homeTitle">
            //       {item.name}
            //     </div>
            //     <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
            //       {/* <Link to={`/loja/produtos?qrcode=${getParamFromUrl('qrcode')}&id_mv_shopkeepers=${getParamFromUrl('id_mv_shopkeepers')}&category_id=${item.id}&category_name=${item.name}`}> */}
            //         Ver todos
            //       {/* </Link> */}
            //     </div>
            //   </div>
            //   <div className="bebidasCarousel">
            //     <Carousel
            //       swipeable={true}
            //       draggable={true}
            //       arrows={false}
            //       showDots={false}
            //       responsive={responsiveBebida}
            //       ssr={true} // means to render carousel on server-side.
            //       partialVisible={true}
            //       infinite={true}
            //       autoPlay={false}
            //       autoPlaySpeed={1000}
            //       keyBoardControl={true}
            //       customTransition="transform 300ms ease-in-out"
            //       transitionDuration={500}
            //       containerClass="carousel-container"
            //       removeArrowOnDeviceType={["tablet", "mobile"]}
            //       deviceType={"mobile"}
            //       dotListClass="custom-dot-list-style"
            //       itemClass="carousel-space"
            //     >
            //       {item.products.map((product, index) => (
            //         product.quantity > 0 && (
            //         <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
            //           <div className="imageBig">
            //             <div className="imageBigItem" style={{backgroundImage: "url(" + (product.banner ? product.banner : product.image) + ")"}}></div>
            //           </div>
            //           {/* div abaixo serve para mostrar produtos selecionados  */}
            //           {/* <div className="iconSelected">
            //             <img src={checked} alt="adicionado" />
            //           </div> */}
            //         </Link>
            //       )))}
            //     </Carousel>
            //   </div>  
            // </div>
            <div className="bebidasArea">
              {item.products.length > 0 ? (
              <div className="divideTitle">
                <div className="homeTitle">
                  {item.name}
                </div>
                <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
                  Ver todos
                </div>
              </div>
              ):('')}
              <div className="carouselDestaque">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  arrows={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  partialVisible={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 200ms ease-in-out"
                  transitionDuration={400}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={"mobile"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-space"
                >
                  {item.products.map((product, index) => (
                    product.quantity > 0 | product.stock == false && (
                      <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
                      <div className="ImageCarousel">
                        <div className="borderCarousel">
                          <div className="image" style={{backgroundImage: "url(" + (product.banner ? product.banner : product.image) + ")"}}></div>
                          <div className="info">
                            <div className="infoDados">
                              <div>
                                <div className="productName">{product.name}</div>
                                <div className="productValue">R$ {product.price_br}</div>
                                {product.descricao !== null && (
                                  <div className="lead">
                                    {product.descricao}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {product.checked ? (
                        <div className="iconSelected">
                          <img src={checked} alt="adicionado" />
                        </div>
                        ):(null)}
                      </div>
                    </Link>
                  )))}
                </Carousel>
              </div>
            </div> 
          :
            <div className="lanchesArea">
              {item.products.length > 0 ? (
              <div className="divideTitle">
                <div className="homeTitle">
                  {item.name}
                </div>
                <div className="verTodos" onClick={() => getPageUrl(item.name, item.id)}>
                    Ver todos
                </div>
              </div>
              ):('')}
              <div className="lanchesAreaCarousel">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  arrows={false}
                  showDots={false}
                  responsive={responsiveProduct}
                  ssr={true} // means to render carousel on server-side.
                  partialVisible={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 200ms ease-in-out"
                  transitionDuration={400}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={"mobile"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-space"
                >
                  {item.products.map((product, index) => (
                    product.quantity > 0 | product.stock == false && (
                    <Link key={index} to={`/loja/add?&product_id=${product.id}`} className="relativeItem">
                      <div className="boxCarousel">
                        <div className={product.image ? 'image' : 'imageCover'} style={{backgroundImage: "url(" + (product.image ? product.image : semProdutos) + ")"}}></div>
                      </div>
                      <div className="nome">
                        {product.name}
                      </div>
                      <div className="valor">
                        R$ {product.price_br}
                      </div>
                      {product.checked ?(
                      <div className="iconSelected">
                        <img src={checked} alt="adicionado" />
                      </div> 
                      ):(null)}
                    </Link>
                  )))}
                </Carousel>
              </div>
            </div>
          }
        </div>
      )}

      {/* estas divs abaixo deverão aparecer após o usuário selecionar algum item */}
      {totalPedido && (
        <>
        <div className="spaceFixed"></div>
        <Link className="areaSelected" to={'/carrinho?shopkeeper='+localStorage.getItem('shopkeeper')}>
          <div className="areaSelectedBox">
            <div className="quantArea">
              {itensPedido}
            </div>
            {cupomTrue ? (
              <div className="valueArea">
                <div className="valorDescontado">
                  R$ {totalPedido}
                </div>
                R$ {cupomDescontoValor}
              </div>
            ) : (
              <div className="valueArea">
                R$ {totalPedido}
              </div>
            )}
            <div className="nextArea">
              <img src={right} alt="avançar" />
            </div>
          </div>
        </Link>
        </>
      )}
      {categories == false && loading == false && (
        <div className="title_bold">
          Ops, nenhum produto encontrado.
        </div>
      )}
      {loading == true && (
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      )}
    </div>
  )
}
