import React, { useState, useRef } from 'react';
import Cards from 'react-credit-cards';
import { useHistory } from 'react-router-dom';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import axios from 'axios';

export default function UserCardAdd() {
  const formCard = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cardAccess, setCardAccess] = useState({
    cvv: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const history = useHistory();
  const maskCard = (input) => {
    return [/[0-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,' ', /\d/, /\d/, /\d/, /\d/]
  }
  const maskDate = (input) => {
    return [/[0-9]/, /\d/, '/', /\d/,/\d/]
  }
  const maskCvv = (input) => {
    return [/[0-9]/, /\d/,  /\d/,  /\d/,  /\d/]
  }
  const handleInputFocus = (e) => {
    setCardAccess({ ...cardAccess, focus: e.target.name });
  }

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const saveCard = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const send = {
        number: cardAccess.number,
        holder_name: cardAccess.name,
        expiration_date: cardAccess.expiry.replace(/[/]+/g, ''),
        cvv: cardAccess.cvv
      }
      const data = await axios.post('order/card-create', send)
      const retorno = getParamFromUrl('return');
      if(retorno) {
        // window.location.href = "/carrinho/cartao";
        history.push("/carrinho/cartao")
      } else {
        // window.location.href = "/perfil/cartao";
        history.push("/perfil/cartao")
      }

      setLoading(false);
    } catch (err) {
      alert(err.response.data);
      setLoading(false);
      console.log(err.response);
    }
  };

  return (
    <div className="content cartaoAdd">
      <div id="PaymentForm">
        <Cards
          cvc={cardAccess.cvv}
          expiry={cardAccess.expiry}
          focused={cardAccess.focus}
          name={cardAccess.name}
          number={cardAccess.number}
          locale={{valid: 'Mês Ano'}}
          placeholders={{ name: 'SEU NOME AQUI' }}
        />
        <div className="tipoArea">
        </div>
        <form ref={formCard} onSubmit={saveCard}>
          <MaskedInput
            required
            mask={maskCard}
            className="ui-input"
            placeholder="Número do cartão"
            guide={false}
            name="number"
            value={cardAccess.number}
            onChange={text => {
              setCardAccess({
                ...cardAccess,
                number: text.target.value,
              });
            }}
            onFocus={(e) => handleInputFocus(e)}
          />
          <input
            required
            className="ui-input"
            type="text"
            name="name"
            placeholder="Nome"
            onChange={text => {
              setCardAccess({
                ...cardAccess,
                name: text.target.value,
              });
            }}
            value={cardAccess.name}
            onFocus={(e) => handleInputFocus(e)}
          />
          <div className="divideInput">
            <div className="left">
              <MaskedInput
                required
                mask={maskDate}
                className="ui-input"
                placeholder="Mês/ano"
                guide={false}
                name="expiry"
                value={cardAccess.expiry}
                onChange={text => {
                  setCardAccess({
                    ...cardAccess,
                    expiry: text.target.value,
                  });
                }}
                onFocus={(e) => handleInputFocus(e)}
              />
            </div>
            <div className="right">
              <MaskedInput
                required
                mask={maskCvv}
                className="ui-input"
                guide={false}
                placeholder="CVV"
                name="cvc"
                value={cardAccess.cvv}
                onChange={text => {
                  setCardAccess({
                    ...cardAccess,
                    cvv: text.target.value,
                  });
                }}
                onFocus={(e) => handleInputFocus(e)}
              />  
            </div>
          </div>
          <div className="buttonPay payNormal">
            <button type="submit" disabled={loading}>
              Adicionar cartão
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
