import React, {useEffect, useState} from 'react';
import {useHistory, Redirect, Link} from 'react-router-dom';
import close from '../../resources/images/close.png';
import more from '../../resources/images/more.png';
import less from '../../resources/images/less.png';
import logo from '../../resources/images/logotipo-preto.png';
import semProdutos from '../../resources/images/sem_produtos.jpg';
import carregamento from '../../resources/images/loading_white.gif';
import Axios from 'axios';

export default function DetailProduct() {
    const [product, setProduct] = useState([]);
    const [observacao, setObservacao] = useState({
        description: '',
    });
    const [observacoes, setObservacoes] = useState([]);
    const [quantidade, setQuantidade] = useState(1);
    const [valor, setValor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showOnly, setShowOnly] = useState(true);
    const [additionals, setAdditionals] = useState([]);

    const history = useHistory();

    const diminuiQuantidade = async () => {
        if (quantidade === 1) {
            setQuantidade(1)
            setarValor(1)
        } else {
            setQuantidade(quantidade - 1)
            setarValor(quantidade - 1)
        }
    };

    const aumentaQuantidade = async () => {
        if (product.stock != false) {
            if (quantidade >= product.quantity) {
                alert('Você não pode pedir mais que ' + product.quantity + ' itens')
                return false
            }
        }
        setQuantidade(quantidade + 1)
        setarValor(quantidade + 1)
    };

    const addObservation = async (e) => {
        try {
            e.preventDefault();
            setObservacoes([...observacoes, observacao.description]);
            setObservacao({description: ''});
        } catch (err) {
            alert('Ops, ocorreu um erro ao adicionar esta observação, por favor tente mais tarde.');
            console.log(err);
        }
    }

    const removeItem = (a) => {
        let itens = observacoes;
        itens.splice(a, 1);
        setObservacoes([...itens]);
    }

    const sendProduct = async () => {
        try {
            if (localStorage.getItem('user') === '{}' || !localStorage.getItem('user')) {
                const id_produto = getParamFromUrl('product_id');
                localStorage.setItem('direcionar_carrinho', id_produto);
                history.push("/login?product=" + id_produto)
                return false
            }
            let atualizar = {
                id_stock_list_product: product.id_stock_list_product,
                action: 1,
                shopkeeper: localStorage.getItem('shopkeeper'),
                mv_shopkeeper: localStorage.getItem('mv_shopkeeper'),
                qrcode: localStorage.getItem('code_qr'),
                amount: quantidade,
                additionals: additionals.filter(additional => additional.quantity > 0),
                observation: observacoes.map((item) => (
                    {text: item}
                ))
            }
            let {data} = await Axios.post('order/cart', atualizar);
            let shopkeep = localStorage.getItem('shopkeeper');
            history.push("/carrinho?shopkeeper=" + shopkeep)
        } catch (err) {
            alert('Ops! ' + err);
            console.log(err);
        }
    }

    const setarValor = async (qnt) => {
        let preco_produto = product.promotional_price > 0 ? product.promotional_price : product.price;
        let valor = (preco_produto * qnt).toFixed(2).replace('.', ',');
        setValor(valor);
    }

    const getParamFromUrl = (param = "") => {
        const url = new URL(window.location.href);
        return url.searchParams.get(param)
    };

    const convertAdditional = ({id, name, image, price}) => {
        return {
            id,
            name,
            image,
            price,
            quantity: 0
        }
    }

    const loadContent = async () => {
        try {
            const productId = getParamFromUrl('product_id');
            const mv_shopkeeper = localStorage.getItem('mv_shopkeeper')
            let url = `webapp/product?id=${productId}&id_mv_shopkeepers=${mv_shopkeeper}`;

            let qrcodeFromStorage = localStorage.getItem('code_qr')
            if (qrcodeFromStorage !== null && qrcodeFromStorage !== '' && qrcodeFromStorage !== '{}') {
                const {data} = await Axios.get(`webapp/list-products?qrcode=${qrcodeFromStorage}&id_mv_shopkeepers=${mv_shopkeeper}`);
                setShowOnly(data.mv.show_only || data.mv?.mv_shopkeepers_show_only)
            }
            const {data} = await Axios.get(url);
            setShowOnly(data.mv_shopkeepers_show_only)
            setProduct(data);
            if (data.additionals) {
                setAdditionals(data.additionals.map(additional => convertAdditional(additional)))
            }
            setLoading(false);
            if (data.promotional_price > 0) {
                setValor(data.promotional_price_br);
            } else {
                setValor(data.price_br);
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    }

    useEffect(() => {
        loadContent();
    }, []);

    const addAdditional = id => {
        const newAdditionals = []
        additionals.forEach(additional => {
            if (additional.id === id) {
                additional.quantity += 1;
                setValor(parseFloat(valor) + parseFloat(additional.price));
            }
            newAdditionals.push(additional)
        });
        setAdditionals(newAdditionals)
    }

    const removeAdditional = id => {
        const newAdditionals = []
        additionals.forEach(additional => {
            if (additional.id === id) {
                if (additional.quantity > 0) {
                    additional.quantity -= 1;
                    setValor(parseFloat(valor) - parseFloat(additional.price));
                }
            }
            newAdditionals.push(additional)
        });
        setAdditionals(newAdditionals)
    }

    const additionalLine = {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: 5
    };

    const additionalText = {
        fontSize: 12,
        paddingBottom: 7
    };

    const additionalButtons = {
        fontSize: 10
    };

    const additionalImage = {
        width: 25,
        height: 25,
        display: 'inline-flex',
        alignItems: 'center'
    };

    const additionalTitle = {
        fontSize: 14,
        marginTop: 15,
        fontWeight: "bold"
    };

    const renderAdditional = ({name, id, image, quantity, price}) => {
        return (
            <div style={additionalLine} class="addProduct" key={id}>
                <div>
                    <img style={additionalImage} src={image ? image : semProdutos} alt="produto"/>
                </div>
                <span style={additionalText}> {name} </span>
                <span style={additionalText}> R$ {price} </span>
                <div className="moreLess">
                    <div style={additionalButtons} className="less" onClick={() => removeAdditional(id)}>
                        <img width={16} src={less} alt="menos"/>
                    </div>
                    <div style={additionalButtons} className="quant">
                        {quantity}
                    </div>
                    <div className="more" onClick={() => addAdditional(id)}>
                        <img width={16} src={more} alt="Mais"/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        loading == false ? (
            <div className="content detalheProduto">
                <div className="productImage">
                    <img src={product.image ? product.image : semProdutos} alt="produto"/>
                </div>
                <div className="detalheProdutoInfo">
                    <div className="title_infoProduct">
                        {product.name}
                    </div>
                    {product.descricao !== null && (
                        <div className="description_product">
                            {product.descricao}
                        </div>
                    )}
                    <div className="productValor">
                        {product.promotional_price > 0 ? (
                            'De R$ ' + product.price_br + ' por R$ ' + product.promotional_price_br
                        ) : (
                            'R$ ' + product.price_br
                        )}
                    </div>
                    {additionals.length > 0 && (
                        <div>
                            <div style={additionalTitle}>Adicionais</div>
                            {additionals.map((additional) => renderAdditional(additional))}
                        </div>
                    )}
                </div>
                {!showOnly && (
                    <>
                        <div className="addInfo">
                            <form onSubmit={addObservation}>
                                <input
                                    value={observacao.description}
                                    onChange={(e) => setObservacao({...observacao, description: e.target.value})}
                                    required
                                    type="text"
                                    placeholder="Adicione uma observação"/>
                                <button type="submit">
                                    Adicionar observação
                                </button>
                            </form>
                            <div className="observacoes">
                                <ul>
                                    {observacoes.length > 0 && observacoes.map((item, index) => (
                                        <li key={index}>
                                            <div className="texto">
                                                {item}
                                            </div>
                                            <div className="button" onClick={() => removeItem(index)}>
                                                <img src={close} alt="Remover"/>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="addProduct">
                            <div className="moreLess">
                                <div className="less" onClick={() => diminuiQuantidade()}>
                                    <img src={less} alt="menos"/>
                                </div>
                                <div className="quant">
                                    {quantidade}
                                </div>
                                <div className="more" onClick={() => aumentaQuantidade()}>
                                    <img src={more} alt="Mais"/>
                                </div>
                            </div>
                            <div className="addInfoProduct" onClick={() => sendProduct()}>
                                <div className="addTitle">
                                    Adicionar
                                </div>
                                <div className="productValue">
                                    {valor && (<span>R$ {parseFloat(valor.toString().replace(',', '.')).toFixed(2).replace('.', ',')}</span>)}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        ) : (
            <div className="content detalheProduto">
                <div className="loading_itens">
                    <img src={carregamento} alt="loading" className="loadingGif"/>
                </div>
            </div>
        )
    )
}
