import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { createHashHistory } from "history";
import success from '../../resources/images/success.jpg';
import analise from '../../resources/images/analise.jpg';
import recusado from '../../resources/images/recusado.jpg';

export default function PaymentSuccess() {
  const [tempo, setTempo] = useState(false);
  const [retorno, setRetorno] = useState(false);
  const history = createHashHistory();
  const changePage = async (a) => {
    let result = JSON.parse(a);
    if(result.data.pagamento !== 'refused') {
      setInterval(() => {
        setTempo(true)
      }, 10000);
    }
    // console.log(result);
  }
  const changeUlr = async () => {
    // let pedidoID = retorno && parseInt(retorno.data.pedido);
    // let shopkeep = retorno && retorno.data.lojista.fk_id_shopkeepers
    setTempo(true);
    // window.location.href = `/pedidos/interna?id=${pedidoID}&shopkeeper=${shopkeep}`;
    // history.push(`/pedidos/interna?id=${pedidoID}&shopkeeper=${shopkeep}`);
    // return <Redirect push to={'/pedidos/interna?id='+pedidoID+'&shopkeeper='+shopkeep} />
    // console.log('/pedidos/interna?id='+pedidoID+'&shopkeeper='+shopkeep);
    // window.location.href = '/pedidos/interna?id='+retorno && parseInt(retorno.data.pedido)+'&shopkeeper='+retorno && retorno.data.lojista.fk_id_shopkeepers;
  }
  const loadContent = async () => {
    setRetorno(JSON.parse(localStorage.getItem('status_pedido')));
    changePage(localStorage.getItem('status_pedido'))
  }
  useEffect(
    () => {
      loadContent();
    },
    []
  );
  if(tempo){
    // changeUlr()
    let pedidoID = retorno && parseInt(retorno.data.pedido);
    let shopkeep = retorno && retorno.data.lojista.fk_id_shopkeepers
    return <Redirect push to={`/pedidos/interna?id=${pedidoID}&shopkeeper=${shopkeep}`} />
  }
  return (
    <div className="content success">
      {(retorno && retorno.data.pagamento === 'success' && (
        <img src={success} alt="Sucesso" />
      ))}
      {(retorno && retorno.data.pagamento === 'analyse' && (
        <img src={analise} alt="Análise" />
      ))}
      {(retorno && retorno.data.pagamento === 'refused' && (
        <img src={recusado} alt="Recusado" />
      ))}
      <div className="titleSuccess">
        {(retorno && retorno.data.pagamento === 'success' && (
          'Pagamento efetuado com sucesso!'
        ))}
        {(retorno && retorno.data.pagamento === 'analyse' && (
          'Pagamento em análise!'
        ))}
        {(retorno && retorno.data.pagamento === 'refused' && (
          'Pagamento não autorizado!'
        ))}
      </div>
      <div className="textSuccess">
        {(retorno && retorno.data.pagamento === 'success' && (
          'Seu pedido foi confirmado, aguarde 10 segundos ou clique abaixo para avançar'
        ))}
        {(retorno && retorno.data.pagamento === 'analyse' && (
          'Seu pedido está em análise, aguarde 10 segundos ou clique abaixo para avançar'
        ))}
        {(retorno && retorno.data.pagamento === 'refused' && (
          'Entre em contato com o banco emissor do seu cartão ou tente novamente'
        ))}
      </div>
      {(retorno && retorno.data.pagamento === 'refused' ? (
        <div className="buttonFixed buttonSucesso">
          <Link to="/carrinho/cartao">
            <button type="button">
              Tentar novamente
            </button>
          </Link>
        </div>
      ) : (
        <div className="buttonFixed buttonSucesso">
          <button type="button" onClick={() => changeUlr()}>
            Avançar
          </button>
        </div>
      ))}
    </div>
  )
}
