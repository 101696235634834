import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import cupomImage from '../../resources/images/cupom.png';
import carregamento from '../../resources/images/loading_white.gif';

export default function Cupom() {
  const history = useHistory();
  const [cupom, setCupom] = useState(null);
  const [cupomLista, setCupomLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cupomTexto, setCupomTexto] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const selecionarCupom = async (codigo,id,index) => {
    setCupom(index);
    try {
      let envio = {
        cupom: codigo,
        cupom_id: id,
        order: localStorage.getItem('id_carrinho'),
        shopkeeper: localStorage.getItem('shopkeeper')
      }
      let data = await Axios.post('order/cupon-aply', envio);
      if(data.status !== 200) {
        alert(data.data);
      }
      // alert(data)
      // window.location.href = `/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper');
      
      history.push(`/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper'));
    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const listaCupons = async () => {
    let {data} = await Axios.get('webapp/cupons?stok_list_id='+localStorage.getItem('stock_list'));
    setCupomLista(data.cupons);
  }

  const adicionaCupom = async (e) => {
    try {
      e.preventDefault();
      setCarregando(true);
      
      let retorno = await Axios.get('order/cart-open?shopkeeper='+localStorage.getItem('shopkeeper'));
      let envio = {
        cupom: cupomTexto,
        cupom_id: 0,
        order: localStorage.getItem('id_carrinho'),
        shopkeeper: localStorage.getItem('shopkeeper')
      }
      let data = await Axios.post('order/cupon-aply', envio);
      if(data.status == 203) {
        alert(data.data);
        setCarregando(false);
      }
      history.push(`/carrinho?shopkeeper=`+localStorage.getItem('shopkeeper'));
      setCarregando(false);
    } catch (err) {
      alert('Ops, ocorreu um erro ao cadastrar o cupom. Tente novamente mais tarde');
      setCarregando(false);
      console.log(err);
    }
  };

  const loadContent = async () => {
    try {
     listaCupons();
     setLoading(false);
    } catch(err) {
      setLoading(false);
      console.log(err);
      console.log(err.response);
    }
  }
  useEffect(() => {
    loadContent();
  }, []);

  return (
    loading == false ? (
    <div className="content cupom">
      <div className="cupomAdd">
        <form onSubmit={adicionaCupom}>
          <input 
            value={cupomTexto} 
            required 
            onChange={(e) => setCupomTexto(e.target.value)}
            type="text" 
            placeholder="Código de cupom" 
          />
          <button type="submit" disabled={carregando}>
            Adicionar
          </button>
        </form>
      </div>
      {/* <div className="cupomNot" onClick={() => setCupom(null)}>
        <div className="cupomNotTitle">
          Sem cupom
        </div>
        <div className="selectArea">
          <div className={cupom === null ? 'selectAreaActive' : ''}></div>
        </div>
      </div> */}
      {cupomLista.length >= 1 ? (
      <div className="cupomLista">
        <ul>
          {cupomLista && cupomLista.map((item,index) => (
            <li key={index} onClick={() => selecionarCupom(item.code, item.id, index)}>
              <div className="cupomListaTopo">
                <div className="nome">
                  <img src={cupomImage} alt="cupom" />
                  {item.code} - {item.type === 'Porcentagem' ? item.value_br+'%' : 'R$ '+item.value_br}
                </div>
                <div className="selectArea">
                  <div className={cupom === index ? 'selectAreaActive' : ''}></div>
                </div>
              </div>
              <div className="text" dangerouslySetInnerHTML={{ __html: item.description }} />
              <div className="termina">
                Valido até {item.finish}
              </div>
            </li>
          ))}
        </ul>
      </div>
      ):(
        <div className="title title_bold">
          Nenhum cupom encontrado :(
        </div>
      )}
    </div>
    ):(
      <div className="content detalheProduto">
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      </div>
    )
  )
}
