import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import pix from '../../resources/images/pix.png';
import carregamento from '../../resources/images/loading_white.gif';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import voltar from '../../resources/images/voltar.png';

export default function PedidosInterna() {
  const [pedido, setPedido] = useState();
  const [tempo, setTempo] = useState(null);
  const [copiado, setCopiado] = useState(false);
  const [loading, setLoading] = useState(true);
  const timer = () => setTempo(tempo - 1);

  const createInterval = () => {
    setTempo(8);
  }

  const getStatus = async () => {
    try {  
        const dados = JSON.parse(localStorage.getItem('status_pedido')).data;
        console.log(dados);
        const id = parseInt(dados.pedido);
        const pagamento = parseInt(dados.customer_order_status);
        const retorno = await Axios.get(`order/status?order=${id}`);
        console.log('Retorno: ', retorno.data.status, 'Pagamento:', pagamento);
        if(parseInt(retorno.data.status) !== pagamento) {
            let pedidoID = dados.pedido;
            let shopkeep = dados.lojista.fk_id_shopkeepers;
            window.location.href = `/pedidos/interna?id=${pedidoID}&shopkeeper=${shopkeep}`;
        } else {
            createInterval()
        }
    } catch(err) {
      console.log(err);
      console.log(err.response);
    }
  }

  const loadContent = async () => {
    try {  
      setPedido(JSON.parse(localStorage.getItem('status_pedido')).data);        
      createInterval()
      setLoading(false)
    } catch(err) {
      setLoading(false)
      console.log(err);
      console.log(err.response);
    }
  }
  
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(
    () => {
      if (tempo <= 0) {
        getStatus();
        return;
      }
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    },
    [tempo]
  );
  return (
    loading == false ? (
    <div className="content pedidosInterna">
      <div className="menuBackArea no_top">
        <Link className="menuBack" to={`/pedidos`}>
          <img src={voltar} alt="voltar" />
        </Link>
      </div>
      <div className="pixBox">
        <img src={pix} alt="pix" className="pixLogotipo"/>
      </div>
      <div className="title centerTitle pix">
        Leia o QR CODE PIX
      </div>
      <div className="qrcodeArea">
        <div className="qrcode_area">
          <div className="item">
            <CopyToClipboard text={pedido.pix_qr_code_codigo} onCopy={() => setCopiado(true)}>
                <div dangerouslySetInnerHTML={{ __html: pedido && pedido.pix_qr_code }} />
            </CopyToClipboard>
          </div>
        </div>
      
        <div className="text">
          {pedido.validade && (
            <span>
              Validade: {pedido && pedido.validade}
            </span>
          )}
        </div>
      </div>
      <div className="title centerTitle">
        Ou clique no QR CODE para copiar o código.
      </div>     
      <div className="title centerTitle">
        {copiado ? <span style={{color: 'green'}}>Copiado :)</span> : null}
      </div>  
        <div className="text centerTitle">
            Aguarde até o pagamento ser confirmado!
        </div>    
    </div>
    ):(
      <div className="content detalheProduto">
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      </div>
    )
  )
}
