import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import hands from '../../resources/images/hands.png';

export default function UserCode() {
  const [info, setInfo] = useState(false);
  const [code, setCode] = useState(false);
  useEffect(
    () => {
      setInfo(JSON.parse(localStorage.getItem('status_pedido')));
      setCode(localStorage.getItem('code_qr'));
    },
    []
    );
  return (
    <div className="content user">
      <div className="userCode">
        <div className="qrcode_area">
          <div className="item">
            <div dangerouslySetInnerHTML={{ __html: info && info.data.qrcode }} />
          </div>
        </div>
        {info && info.data.validade ? (
          <div className="userValidade">
            Validade: {info && info.data.validade}
          </div>
        ) : (null)}
      </div>
      <div className="userName">
        Olá! {info && info.data.nome} <img src={hands} alt="olá" />
      </div>
      <div className="text">
        Esse é o seu QR CODE... É só apresentar este código quando seu pedido ficar pronto. Ele também pode ser acessado em "Meus pedidos".
      </div>
      <div className="entregaArea">
        {info && info.data.entrega !== '0' ? (
        <div className="itemLeft">
          Entrega:
        </div>
        ) : (null)}
        {info && info.data.entrega !== '0' ? (
        <div className="itemRight">
          {info && info.data.entrega}
        </div>
        ) : (null)}
      </div>
      <div className="redirectButtons">
        <Link to={'/?qrcode='+code}>
          <button type="button" className="homeButton">
            Ir para Home
          </button>
        </Link>
        <Link to="/pedidos">
          <button type="button" className="pedidosButton">
            Meus pedidos
          </button>
        </Link>
      </div>
    </div>
  )
}
