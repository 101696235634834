import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import semProdutos from '../../resources/images/sem_produtos.jpg';
import carregamento from '../../resources/images/loading_white.gif';
import Axios from 'axios';

export default function PedidosInterna() {
    const [pedido, setPedido] = useState([]);
    const [shop, setShop] = useState();
    const [tempo, setTempo] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const [pagamento, setPagamento] = useState([]);
    const [loading, setLoading] = useState(true);
    const [erroApi, setError] = useState(false);
    const timer = () => setTempo(tempo - 1);
    const getParamFromUrl = (param = "") => {
        const url = new URL(window.location.href);
        return url.searchParams.get(param)
    };

    const createInterval = () => {
        setTempo(30);
    }

    const getStatus = async () => {
        try {
            const id = getParamFromUrl('id');
            const retorno = await Axios.get(`order/status?order=${id}`);
            if (retorno.data.status !== pedido.status) {
                loadContent();
            } else {
                createInterval()
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    }

    const loadContent = async () => {
        try {
            const id = getParamFromUrl('id');
            const shopkeeper = getParamFromUrl('shopkeeper');
            const data = await Axios.get(`order/cart-open?shopkeeper=${shopkeeper}&order_id=${id}`);
            setError(false);
            setPedido(data.data);
            setShop(data.data.shopkeeper);
            setProdutos(data.data.items);
            setPagamento(data.data.payment_type)
            createInterval()
            setLoading(false)
        } catch (err) {
            setLoading(false);
            setError(true);
            console.log(err);
            console.log(err.response);
        }
    }

    useEffect(() => {
        loadContent();
    }, []);

    useEffect(
        () => {
            if (tempo <= 0) {
                getStatus();
                return;
            }
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        },
        [tempo]
    );

    const additionalTitle = {
        fontSize: 14,
        marginTop: 15,
        fontWeight: "bold",
    };

    return (
        loading == false ? (
            <div className="content pedidosInterna">
                {!erroApi ? (
                    <div>
                        <div className="title centerTitle">
                            Seu QR code
                        </div>
                        <div className="qrcodeArea">
                            <div className="qrcode_area">
                                <div className="item">
                                    <div dangerouslySetInnerHTML={{__html: pedido && pedido.qrcode}}/>
                                </div>
                            </div>
                            <div className="text">
                                {pedido.validade && (
                                    <span>Validade: {pedido && pedido.validade}</span>
                                )}
                            </div>
                        </div>
                        <div className="timeline">
                            <div className="one active-one">
                                <div className="timelineText">
                                    Recebido
                                </div>
                            </div>
                            <div className="two active-two">
                                <div className="timelineText">
                                    {pedido.status === '1' && ('Aguardando pagamento')}
                                    {pedido.status === '2' && ('Pagamento em análise')}
                                    {pedido.status === '3' && (
                                        <span className="confirmado">Pagamento confirmado</span>
                                    )}
                                    {pedido.status === '4' && ('Em produção')}
                                    {pedido.status === '5' && ('Realizado')}
                                    {pedido.status === '6' && ('Parcial')}
                                    {pedido.status === '7' && ('Finalizado')}
                                    {pedido.status === '8' && (
                                        <span className="cancelado">Negado</span>
                                    )}
                                    {pedido.status === '9' && (
                                        <span className="cancelado">Cancelado</span>
                                    )}
                                    {pedido.status === '10' && ('Expirado')}
                                    {pedido.status === '11' && ('Reembolsado')}
                                    {pedido.status === '12' && ('Reembolsado')}
                                </div>
                            </div>
                            <div
                                className={pedido.status === '5' || pedido.status === '7' ? 'three active-three' : 'three'}>
                                {pedido.status === '5' || pedido.status === '7' ? (
                                    <div className="timelineText">
                                        <span className="confirmado">
                                          Pronto
                                        </span>
                                    </div>
                                ) : (
                                    <div className="timelineText">
                                        <span className="confirmado">
                                          <div className="oculto">
                                            Fim
                                          </div>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="entregaArea">
                            <div className="title">
                                Entrega:
                            </div>
                            <div className="title rightTitle">
                                {pedido && pedido.entrega}
                            </div>
                        </div>
                        <div className="localSenha">
                            <div className="localSenhaInfo">
                                <div className="imageArea">
                                    {shop && shop.mv_shopkeepers_image && (
                                        <div className="image"
                                             style={{backgroundImage: "url(" + shop.mv_shopkeepers_image + ")"}}></div>
                                    )}
                                </div>
                                {shop && (
                                    <div className="text">
                                        {shop.mv_shopkeepers_name ? shop.mv_shopkeepers_name : shop.mv_shopkeepers_legal_name}
                                    </div>
                                )}
                            </div>
                            {pedido && pedido.senha_pedido ? (
                                <div className="text">
                                    Senha {pedido && pedido.senha_pedido}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {shop && shop.mv_shopkeepers_use_service_charge && pedido.apos_service_fee_value && (
                            <div className="totalTaxaServico" >
                                <div className="title leftTitle">
                                    Taxa de Serviço:
                                </div>

                                <div>
                                    <div className="title rightTitle">
                                        R$ {pedido && pedido.apos_service_fee_value}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="totalDetalhe">
                            <div className="title leftTitle">
                                Total:
                            </div>
                            {pedido && pedido.cupom_order ? (
                                <div>
                                    <div className="title rightTitle valor_riscado no_bottom">
                                        R$ {pedido && pedido.total_price_br}
                                    </div>
                                    <div className="title rightTitle">
                                        R$ {pedido && pedido.total_price_desconto}
                                    </div>
                                </div>
                            ) : (
                                <div className="title rightTitle">
                                    R$ {pedido && pedido.total_price_br}
                                </div>
                            )}
                        </div>
                        <div className="totalDetalhe top_detalhe">
                            <div className="title leftTitle">
                                Pagamento:
                            </div>
                            <div className="title rightTitle">
                                {pagamento === '1' ? 'Cartão' : ''}
                                {pagamento === '2' ? 'Caixa' : ''}
                                {pagamento === '3' ? 'Pix' : ''}
                            </div>
                        </div>
                        <div className="title">
                            Produtos
                        </div>
                        <div className="productsList">
                            <ul>
                                {produtos.map((prod, index) => (
                                    <li style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: '1rem',
                                        backgroundColor: '#F8F8F8',
                                        borderRadius: 8
                                    }} key={index}>
                                        <div style={{display: "flex", flexDirection: "row", width:"100%"}}>
                                            <div className="produtoArea">
                                                <img src={prod.image ? prod.image : semProdutos} alt="Produto"/>
                                            </div>
                                            <div className="dadoProduto">
                                                <div className="nomeProduto">
                                                    {prod.name}
                                                </div>
                                                <div className="quantidadeArea">
                                                    <div className="quantidade">
                                                        {prod.amount}
                                                    </div>
                                                </div>
                                            </div>
                                            {prod.cupom ? (
                                                <div className="valorProduto">
                                                    <div
                                                        className="valorDesconto valor_riscado">R$ {prod.price_original}</div>
                                                    R$ {prod.total_price_br}
                                                </div>
                                            ) : (
                                                <div className="valorProduto">
                                                    R$ {prod.total_price_br}
                                                </div>
                                            )}
                                        </div>

                                        {prod.additionals.length > 0 && (
                                            <ul style={{width:"100%"}}>
                                                <li>
                                                    <div style={additionalTitle}>Adicionais</div>
                                                </li>
                                                {prod.additionals.map((additional, index) => (
                                                    <li style={{fontSize: 12}}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: '100%',
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <div>
                                                                {additional.quantity} - {additional.name} (R$ {additional.price})
                                                            </div>
                                                            <div>
                                                                R$ {additional.amount}
                                                            </div>
                                                        </div>
                                                    </li>

                                                ))}
                                            </ul>
                                        )}

                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="other">
                            <Link
                                to={`/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`}>
                                <button>
                                    Continuar comprando
                                </button>
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="title centerTitle">
                            Pedido não encontrado ou cancelado
                        </div>
                        <div className="buttonNotFound">
                            <a href="/pedidos">
                                <button>
                                    Clique aqui para voltar
                                </button>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className="content detalheProduto">
                <div className="loading_itens">
                    <img src={carregamento} alt="loading" className="loadingGif"/>
                </div>
            </div>
        )
    )
}