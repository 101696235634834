import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import notification from '../../resources/images/notification.png';
export default function NotificationNotation() {
  // const location = useLocation();

  return (
    <div>
      {/* {location.pathname !== '/' && 
      location.pathname !== '/loja/detalhes' && 
      location.pathname !== '/carrinho' && 
      location.pathname !== '/login' && 
      location.pathname !== '/cadastro/token' && 
      location.pathname !== '/login/cartao' && 
      location.pathname !== '/loja/detalhes' && 
      location.pathname !== '/login/cartao/add' && 
      location.pathname !== '/user/qrcode' && 
      location.pathname !== '/perfil' && 
      location.pathname !== '/perfil/cartao/adicionar' && 
      location.pathname !== '/pagamento/sucesso' && (
        <div className="notificacao">
          <div className="notificacaoItem">
            <div className="notificacaoItemQuant">
              2
            </div>
            <Link to={'/notificacoes'}>
              <img src={notification} alt="notificação" />
            </Link>
          </div>
        </div>
      )} */}
    </div>
  )
}
