import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import card from '../../resources/images/bandeiras/mastercard.png';
import mastercard from '../../resources/images/bandeiras/mastercard.png';
import visa from '../../resources/images/bandeiras/visa.jpg';
import hipercard from '../../resources/images/bandeiras/hipercard.jpg';
import elo from '../../resources/images/bandeiras/elo.jpg';
import amex from '../../resources/images/bandeiras/amex.jpg';
import aura from '../../resources/images/bandeiras/aura.jpg';
import discover from '../../resources/images/bandeiras/discover.jpg';
import jcb from '../../resources/images/bandeiras/jcb.jpg';
import pix from '../../resources/images/bandeiras/pix.jpg';
import newCard from '../../resources/images/novoCartao.jpg';
import money from '../../resources/images/bandeiras/money.png';
import carregamento from '../../resources/images/loading_white.gif';
import MaskedInput from 'react-text-mask';
import axios from 'axios';


export default function Card() {
  const [check, setCheck] = useState(null);
  const [sending, setSending] = useState(false);
  const [cartao, setCartao] = useState(null);
  const [pay, setPay] = useState(0);
  const [cards, setCards] = useState([]);
  const [cvv, setCvv] = useState(null);
  const [store, setStore] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const mask = (input) => {
    return [/[0-9]/, /\d/,  /\d/,  /\d/,  /\d/]
  }

  const getCards = async () => {
    let {data} = await axios.get('order/card-list');
    if(data === 'Nenhum cartão encontrado!') {
      setCards([]);
    } else {
      setCards(data);
    }
  }
  const selecionaCartao = async (index, id) => {
    setCartao(id);
    setCheck(index);
  }

  const sendByGold = async () => {
    setSending(true);
    // console.log(localStorage.getItem('pedido_id'));
    if(!localStorage.getItem('pedido_id')) {
      alert('Ops! pedido não encontrado.');
      window.location.href = "/";
    }
    try {
      let itens = {
        order_id: localStorage.getItem('pedido_id'),
        card_id: '',
        cvv: '',
        payment_type: 2,
      }
      // console.log(itens);
      let data = await axios.post('order/create', itens);
      localStorage.setItem('status_pedido', JSON.stringify(data));
      setSending(false);
      if(data.data == 'Itens do carrinho não encontrado!') {
        return window.location.href = "/pedidos";
      }
      if(data.status === 200) {
        // window.location.href = "/pagamento/sucesso-caixa";
        history.push("/pagamento/sucesso-caixa")
      }
      if(data.status === 201) {
        // window.location.href = "/pagamento/sucesso-caixa";
        history.push("/pagamento/sucesso-caixa")
      }
      if(data.status === 202) {
        alert('Carrinho vazio ou pagamento já gerado!');
        window.location.href = "/";
      }
      if(data.status === 203) {
        alert(data.data);
        // alert('Cartão de crédito ou CVV inválido!');
      }
      if(data.status === 206) {
        alert(data.data.errors);
        window.location.href = "/";
      }
      if(data.status === 406) {
        alert('Erro ao processar pagamento, por favor tente novamente.');
      }
    } catch (err) {
      setSending(false);
      alert('Ops, '+err);
      console.log(err);
    }
  }
  const sendByPix = async () => {
    setSending(true);
    // console.log(localStorage.getItem('pedido_id'));
    if(!localStorage.getItem('pedido_id')) {
      alert('Ops! pedido não encontrado.');
      window.location.href = "/";
    }
    try {
      let itens = {
        order_id: localStorage.getItem('pedido_id'),
        card_id: '',
        cvv: '',
        payment_type: 3,
      }
      let data = await axios.post('order/create', itens);

      if(data.data == 'Itens do carrinho não encontrado!') {
        return window.location.href = "/pedidos";
      }
      localStorage.setItem('status_pedido', JSON.stringify(data));
      if(data.status === 200) {
        // window.location.href = "/pagamento/sucesso-caixa";
        history.push("/pagamento/pix-aguardar")
      }     
      if(data.status === 201) {
        // window.location.href = "/pagamento/sucesso-caixa";
        history.push("/pagamento/pix-aguardar")
      }
      if(data.status === 202) {
        alert('Carrinho vazio ou pagamento já gerado!');
        window.location.href = "/";
      }
      
      if(data.status === 203) {
        alert(data.data);
        window.location.href = `/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`;
        // alert('Cartão de crédito ou CVV inválido!');
      }
      if(data.status === 206) {
        alert(data.data.errors);
        window.location.href = "/";
      }
      if(data.status === 406) {
        alert('Erro ao processar pagamento, por favor tente novamente.');
      }
    } catch (err) {
      setSending(false);
      localStorage.removeItem('pedido_id');
      localStorage.removeItem('id_carrinho');
      alert('Ops, erro ao processar pagamento, por favor tente novamente.');
      window.location.href = `/loja/detalhes?qrcode=${localStorage.getItem('code_qr')}&id_mv_shopkeepers=${localStorage.getItem('mv_shopkeeper')}`;
      console.log(err);
    }
  }
  
  const sendPedido = async () => {
    setSending(true);
    if(cartao == 999998){
      sendByGold();
      return false;
    }
    if(cartao == 999999){
      sendByPix();
      return false;
    }
    // console.log(localStorage.getItem('pedido_id'));
    if(!localStorage.getItem('pedido_id')) {
      alert('Ops! pedido não encontrado.');
      window.location.href = "/";
    }
    try {
      let itens = {
        order_id: localStorage.getItem('pedido_id'),
        card_id: cartao,
        cvv: cvv,
      }
      // console.log(itens);
      let data = await axios.post('order/create', itens);
      localStorage.setItem('status_pedido', JSON.stringify(data));
      if(data.data == 'Itens do carrinho não encontrado!') {
        return window.location.href = "/pedidos";
      }
      setSending(false);
      if(data.status === 200) {
        // window.location.href = "/pagamento/sucesso";
        history.push("/pagamento/sucesso")
      }
      if(data.status === 201) {
        // window.location.href = "/pagamento/sucesso";
        history.push("/pagamento/sucesso")
      }
      if(data.status === 202) {
        alert('Carrinho vazio ou pagamento já gerado!');
        window.location.href = "/";
      }
      if(data.status === 203) {
        alert(data.data);
        // alert('Cartão de crédito ou CVV inválido!');
      }
      if(data.status === 206) {
        alert(data.data.errors);
        window.location.href = "/";
      }
      if(data.status === 406) {
        alert('Erro ao processar pagamento, por favor tente novamente.');
      }
    } catch (err) {
      console.log('catch')
      setSending(false);
      alert('Ops, '+err);
      console.log(err);
    }
  }

  const loadContent = async () => {
    await getCards();
    setLoading(false);
    setStore(JSON.parse(localStorage.getItem('store')));
  };
  
  useEffect(() => {
    loadContent();
  }, []);

  return (
    loading == false ? (
    <div className="content cartao pb_buttons_fixed">     
      <div className="cartaoList">
        <ul>
          {store && store.mv_shopkeepers_cash_payment === true ? (
            <li key="999998">
                <div 
                  className={check === 999998 ? 'cardSelected selected' : 'cardSelected'} 
                  onClick={() => selecionaCartao(999998, 999998)}>
                  <div className="infoLeft uppercase_card">
                      <img src={money} alt="Caixa" />
                      &nbsp;&nbsp;Registrar pedido
                  </div>
                  <div className="selectArea">
                    <div className={check === 999998 ? 'selectAreaActive' : ''}></div>
                  </div>
                </div>
            </li>
          ) : ('')}
          {store && store.mv_shopkeepers_pix_payment === true ? (
          <li key="999999">
            <div 
              className={check === 999999 ? 'cardSelected selected' : 'cardSelected'} 
              onClick={() => selecionaCartao(999999, 999999)}>
              <div className="infoLeft uppercase_card">
                  <img src={pix} alt="Pix" />
                  Pagamento com PIX
              </div>
              <div className="selectArea">
                <div className={check === 999999 ? 'selectAreaActive' : ''}></div>
              </div>
            </div>
          </li>
          ) : ('')}
        </ul>
      </div>
            
      {cards && cards.length > 0 ? (
        <div className="cartaoList">
          <ul>
            {cards && cards.map((item,index) => (
            <li key={index}>
              <div 
                className={check === index ? 'cardSelected selected' : 'cardSelected'} 
                onClick={() => selecionaCartao(index, item.id_customer_card)}>
                <div className="infoLeft uppercase_card">
                  {item.customer_card_flag === 'mastercard' && (
                    <img src={mastercard} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'visa' && (
                    <img src={visa} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'hipercard' && (
                    <img src={hipercard} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'elo' && (
                    <img src={elo} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'amex' && (
                    <img src={amex} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'aura' && (
                    <img src={aura} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'discover' && (
                    <img src={discover} alt="cartão" />
                  )}
                  {item.customer_card_flag === 'jcb' && (
                    <img src={jcb} alt="cartão" />
                  )}
                  {item.customer_card_flag}
                  <div className="number">
                    {item.customer_card_numbers}
                  </div>
                </div>
                <div className="selectArea">
                  <div className={check === index ? 'selectAreaActive' : ''}></div>
                </div>
              </div>
            </li>
            ))}
          </ul>
        </div>
      ) : (
        
        <div className="novoCartao">
          {store && store.mv_shopkeepers_credit_card_payment === true ? (
            <div>
              <img src={newCard} alt="Novo Cartão" />
              <div className="newText">
                Você ainda não tem cartões cadastrados.
              </div>
              <div className="newTextBold">
                Cadastre seu cartão para continuar a compra.
              </div>
            </div>
          ) : ('')}
        </div>
      )}
      {cards && cards.length > 0 && (
      <div className="other">
        <Link to="/perfil/cartao/adicionar?return=pedido">
          <button>
            {cards && cards.length > 0 ? 'Adicionar outro' : 'Adicionar novo'}
          </button>
        </Link>
      </div>
      )}
      {cards && cards.length > 0 &&  check < 9999 && (
      <div className={pay === 0 ? 'proximoArea' : 'hide'}>
        <button type="button" onClick={() => setPay(1)} disabled={check === null}>
          Confirmar
        </button>
      </div>
       )}
      {store && store.mv_shopkeepers_credit_card_payment === true ? (
        <div>
          {cards && cards.length <= 0 && (
            <div className="buttonFixed buttonPagamento">
              <Link to="/perfil/cartao/adicionar?return=pedido">
                <button type="button">
                  Adicionar novo Cartão
                </button>
              </Link>
            </div>
          )}
        </div>
       ) : ('')}
      {check > 9999 &&
        <div className={pay === 0 ? 'proximoArea' : 'hide'}>
          <button type="button" onClick={() => sendPedido()} disabled={sending}>
            Confirmar
          </button>
        </div>
      }
      {/* div que fica por cima de tudo que pode ser fechada ao clicar */}
      <div className={pay === 1 ? 'activeBG' : 'hide'} onClick={() => setPay(0)}></div>
      <div className={pay === 1 ? 'modalPay' : 'modalPay hide'}>
        <div className={pay === 1 ? 'cvvArea' : 'hidecvv'}>
          <div className="cvvTitle">
            CVV
          </div>
          <div className="inputArea">
            <MaskedInput
              mask={mask}
              className="ui-input"
              guide={false}
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
            <div className="inputLead">
              Código que fica atrás do cartão em 3 dígitos
            </div>
          </div>
          <div className="buttonPay">
            <button type="button" onClick={() => sendPedido()} disabled={sending}>
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
     ):(
      <div className="content detalheProduto">
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      </div>
    )
  )
}
