import React from 'react';
import { Link } from 'react-router-dom';
import destaque from '../../resources/images/home/destaque.jpg';

export default function DestaquesList() {

  return (
    <div className="content destaquesList">
      <ul>
        {[1,2,3,4,5,6,7,8,9,10].map((index) => (
          <li key={index}>
            <Link to={'/loja/add'}>
              <div className="destaqueImagem">
                <img src={destaque} alt="Destaque" />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
