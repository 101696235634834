import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import mastercard from '../../resources/images/bandeiras/mastercard.png';
import visa from '../../resources/images/bandeiras/visa.jpg';
import hipercard from '../../resources/images/bandeiras/hipercard.jpg';
import elo from '../../resources/images/bandeiras/elo.jpg';
import amex from '../../resources/images/bandeiras/amex.jpg';
import aura from '../../resources/images/bandeiras/aura.jpg';
import discover from '../../resources/images/bandeiras/discover.jpg';
import jcb from '../../resources/images/bandeiras/jcb.jpg';
import remove from '../../resources/images/remove.jpg';
import axios from 'axios';

export default function UserCard() {
  const [apagar, setApagar] = useState(false);
  const [cards, setCards] = useState([]);
  const [cardDelete, setCardDelete] = useState({});

  const getCards = async () => {
    let {data} = await axios.get('order/card-list');
    if(data === 'Nenhum cartão encontrado!') {
      setCards([]);
    } else {
      setCards(data);
    }
  }
  const deleteCard = async (item) => {
    try {
      let deletar = await axios.delete('order/card-delete?id_customer_card='+item);
      setApagar(false)
      getCards()

    } catch (err) {
      alert('Ops, ocorreu um erro ao remover este cartão. Por favor tente novamente mais tarde.');
      setApagar(false)
      console.log(err.response);
    }
  }

  const apagarCard = async (item) => {
    setApagar(true);
    setCardDelete(item);
  }

  const loadContent = async () => {
    await getCards();
  };
  
  useEffect(() => {
    loadContent();
  }, []);

  return (
    <div className="content perfil cartao">
      <div className="cartaoList">
        <ul>
          {cards && cards.map((item,index) => (
          <li key={index}>
            <div 
              onClick={() => apagarCard(item)}
              className="cardSelected">
              <div className="infoLeft uppercase_card">
                {item.customer_card_flag === 'mastercard' && (
                  <img src={mastercard} alt="cartão" />
                )}
                {item.customer_card_flag === 'visa' && (
                  <img src={visa} alt="cartão" />
                )}
                {item.customer_card_flag === 'hipercard' && (
                  <img src={hipercard} alt="cartão" />
                )}
                {item.customer_card_flag === 'elo' && (
                  <img src={elo} alt="cartão" />
                )}
                {item.customer_card_flag === 'amex' && (
                  <img src={amex} alt="cartão" />
                )}
                {item.customer_card_flag === 'aura' && (
                  <img src={aura} alt="cartão" />
                )}
                {item.customer_card_flag === 'discover' && (
                  <img src={discover} alt="cartão" />
                )}
                {item.customer_card_flag === 'jcb' && (
                  <img src={jcb} alt="cartão" />
                )}
                {item.customer_card_flag}
                <div className="number">
                  {item.customer_card_numbers}
                </div>
              </div>
              <div className="remover_card">
                <img src={remove} alt="remover" />
              </div>
            </div>
          </li>
          ))}
        </ul>
      </div>
      <div className="other">
        <Link to="/perfil/cartao/adicionar">
          <button>
            Adicionar cartão
          </button>
        </Link>
      </div>
      <div className={apagar === true ? 'sairApp' : 'sairApp hide'}>
        <div className="sairBoxCenter">
          <div className="sairBoxArea">
            <div className="sairText">
              <div className="marginCard">
                Cartão: <b>{cardDelete.customer_card_flag} - {cardDelete.customer_card_numbers}</b>
              </div>
              Deseja apagar este cartão?
            </div>
          </div>
          <button type="button" className="sairNao" onClick={() => setApagar(false)}>
            Não
          </button>
          <button type="button" className="sairSim" onClick={() => deleteCard(cardDelete.id_customer_card)}>
            Sim
          </button>
        </div>
      </div>
    </div>
  )
}
