import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';

export default function CardAdd() {
  const [tipo, setTipo] = useState(0);
  const [cardAccess, setCardAccess] = useState({
    cvv: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const maskCard = (input) => {
    return [/[0-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,' ', /\d/, /\d/, /\d/, /\d/]
  }
  const maskDate = (input) => {
    return [/[0-9]/, /\d/, '/', /\d/,/\d/]
  }
  const maskCvv = (input) => {
    return [/[0-9]/, /\d/,  /\d/,  /\d/,  /\d/]
  }
  const handleInputFocus = (e) => {
    setCardAccess({ ...cardAccess, focus: e.target.name });
  }
  return (
    <div className="content cartaoAdd">
      <div id="PaymentForm">
        <Cards
          cvc={cardAccess.cvv}
          expiry={cardAccess.expiry}
          focused={cardAccess.focus}
          name={cardAccess.name}
          number={cardAccess.number}
          locale={{valid: 'Mês Ano'}}
          placeholders={{ name: 'SEU NOME AQUI' }}
        />
        <div className="tipoArea">
          <button className={tipo === 0 ? 'selected left' : 'left'} type="button" onClick={() => setTipo(0)}>
            Débito
          </button>
          <button className={tipo === 1 ? 'selected right' : 'right'} type="button" onClick={() => setTipo(1)}>
            Crédito
          </button>
        </div>
        <form>
          <MaskedInput
            mask={maskCard}
            className="ui-input"
            placeholder="Número do cartão"
            guide={false}
            name="number"
            value={cardAccess.number}
            onChange={text => {
              setCardAccess({
                ...cardAccess,
                number: text.target.value,
              });
            }}
            onFocus={(e) => handleInputFocus(e)}
          />
          <input
            className="ui-input"
            type="text"
            name="name"
            placeholder="Nome"
            onChange={text => {
              setCardAccess({
                ...cardAccess,
                name: text.target.value,
              });
            }}
            value={cardAccess.name}
            onFocus={(e) => handleInputFocus(e)}
          />
          <div className="divideInput">
            <div className="left">
              <MaskedInput
                mask={maskDate}
                className="ui-input"
                placeholder="Mês/ano"
                guide={false}
                name="expiry"
                value={cardAccess.expiry}
                onChange={text => {
                  setCardAccess({
                    ...cardAccess,
                    expiry: text.target.value,
                  });
                }}
                onFocus={(e) => handleInputFocus(e)}
              />
            </div>
            <div className="right">
              <MaskedInput
                mask={maskCvv}
                className="ui-input"
                guide={false}
                placeholder="CVV"
                name="cvc"
                value={cardAccess.cvv}
                onChange={text => {
                  setCardAccess({
                    ...cardAccess,
                    cvv: text.target.value,
                  });
                }}
                onFocus={(e) => handleInputFocus(e)}
              />  
            </div>
          </div>
          <div className="buttonPay payNormal">
            <Link to="/login/cartao">
              <button type="button">
                Adicionar cartão
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}
