import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import qrcode from '../../resources/images/icon-qrcode.png';

export default function BackQrcode() {
  const location = useLocation();
  return (
    <div className="qrcodeButton">
      {location.pathname === '/loja/detalhes' && (
        <Link to={'/'}>
          <img src={qrcode} alt="QrCode" />
        </Link>
      )}
    </div>
  )
}