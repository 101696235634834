import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import ReactCodeInput from 'react-verification-code-input';

export default function EsqueciSenhaToken() {
  const [tempo, setTempo] = useState(null);
  const [code, setCode] = useState('');
  const timer = () => setTempo(tempo - 1);
  const history = useHistory();
  const createInterval = () => {
    setTempo(50);
  }

  const resendNotification = async () => {
    if(tempo) {
      return true
    }
    try {
      let dadosForgot = JSON.parse(localStorage.getItem('dadosForgot'));
      let info = {
        phone: dadosForgot.phone
      }
      const { data } = await Axios.post(`customers/password-sms-resend`, info);
    } catch(err) {
      console.log(err.response);
    } finally {
      createInterval();
    }
  }

  const send = async () => {
    if (code.length != 4) {
      alert('Preencha o código!')
      return;
    }

    try {
      let dadosForgot = JSON.parse(localStorage.getItem('dadosForgot'));
      let dados = {
        phone: dadosForgot.phone,
        verified_code: code
      }
      let retorno = await Axios.post(`customers/password-sms-verify`, dados);
      localStorage.setItem('novosDados', JSON.stringify(dados));
      localStorage.removeItem('dadosForgot');
      history.push("/esqueci-a-senha/nova-senha");
    } catch(err) {
      console.log(err.response);
      alert(err.response.data);
      createInterval();

    }

  }

  useEffect(() => {
    createInterval()
  }, []);

  useEffect(
    () => {
      if (tempo <= 0) {
        return;
      }
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    },
    [tempo]
  );
  return (
    <div className="content token">
      <div className="tokenText">
        Enviaremos uma mensagem para o seu celular. 
        <b> Digite o código</b> que aparecerá no seu sms
      </div>
      <div className="tokenTitle">
        Token
      </div>
      <div className="tokenArea">
        <ReactCodeInput
          fields={4}
          fieldHeight={50}
          fieldWidth={50}
          className="tokenItem"
          onComplete={val => {
            setCode(val)
          }}
        />
      </div>
      
        <div className="resendArea">
          <button className="resend" onClick={() => resendNotification()}>
            Reenviar
          </button>
          {tempo != null && (
          <div className="time">
            {tempo > 10 ? '0:'+tempo : '0:0'+tempo}
          </div>
          )}
        </div>
     
      <div className="proximoArea">
        <button type="button" onClick={send}>
          Próximo
        </button>
      </div>
    </div>
  )
}