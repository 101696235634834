import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Axios from 'axios';
import * as serviceWorker from './serviceWorker';

// import ScriptTag from 'react-script-tag';
// const Demo = props => (
// <ScriptTag type="text/javascript" src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" />
// )

  // Set API url
  Axios.defaults.baseURL = 'https://api.yuzer.com.br/api/';
  // Axios.defaults.baseURL = 'https://yuzer-api.test/api/';

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
