import React, { useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import MaskedInput from 'react-text-mask';

export default function EsqueciSenha() {
  const formLogin = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [forgot, setForgot] = useState({
    phone: '',
  });

  const phone = (input) => {
    if (input && input.length >= 14) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }

  const send = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const send = {
        phone: forgot.phone.replace('(','').replace(')', '').replace('-', '').replace(' ', '')
      }
      const {data} = await axios.post('customers/password-reset', send)
      let dados = {
        phone: forgot.phone.replace('(','').replace(')', '').replace('-', '').replace(' ', ''),
        verified_phone: data.response
      }
      localStorage.setItem('dadosForgot', JSON.stringify(dados));
      history.push("/esqueci-a-senha/token");
      setLoading(false);
    } catch (err) {
      alert('Ops, o telefone não foi encontrado!');
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="content login">
       <div className="loginTitle">
        Esqueci a senha
      </div>
      <div className="loginText">
        Preencha o telefone abaixo para recuperar sua conta
      </div>
      <form ref={formLogin} onSubmit={send}>
        <MaskedInput
          value={forgot.phone}
          onChange={(e) => setForgot({ ...forgot, phone: e.target.value })}
          mask={phone}
          className="ui-input"
          placeholder="Telefone"
          guide={false}
        />
        <div className="buttonArea right_button">
          <button type="submit" disabled={loading}>
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}
