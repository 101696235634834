import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import todas from '../../resources/images/home/todas.png';
import logo from '../../resources/images/logotipo-preto.png';
import Search from '../../resources/images/menu/search.png';
import semProdutos from '../../resources/images/sem_produtos.jpg';

export default function Home() {
  const [qrcode, setQrcode] = useState('');
  const [activeCategorie, setActiveCategorie] = useState(0);
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const [stores, setStores] = useState([]);
  const [storesFiltered, setStoresFiltered] = useState([]);
  const [search, setSearch] = useState('');
  // const history = useHistory();
  
  const responsiveCategorie = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      partialVisibilityGutter: 15
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 18
    }
  };

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 50
    }
  };

  const getBanners = async (a) => {
    try {
      let store = JSON.parse(localStorage.getItem('store'));
      let dados = '';
      let shops = a.map((item, index) => (
        dados = item.id_loja
      ))
      let {data} = await Axios.get('banners/list?shop='+shops+'&mv='+localStorage.getItem('mv'));
      setBanners(data);
    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const gotoItem = async (a) => {
    let selecionado = '';
    let itens = storesFiltered.filter((item)=> item.id_loja == a)
    selecionado = itens[0]
    if(selecionado.status_funcionamento === false) {
      return false
    }
    localStorage.setItem('store', JSON.stringify(selecionado));
    localStorage.setItem('shopkeeper', selecionado.id_loja);
    window.location.href= `/loja/detalhes?qrcode=${qrcode}&id_mv_shopkeepers=${selecionado.id_mv_shopkeepers}`;

  }

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };

  const loadContent = async () => {
    try {
      const qrcode = getParamFromUrl('qrcode');

      let qrcodeStorage = localStorage.getItem('code_qr');
      if(qrcodeStorage) {
        if(qrcodeStorage != qrcode) {
          return window.location.href= `/?qrcode=${qrcode}`
        }
      } else {
        return window.location.href= `/?qrcode=${qrcode}`
      }

      const { data } = await Axios.get(`webapp/list-products?qrcode=${qrcode}`);
      setQrcode(qrcode);
      data.categories.unshift({name: 'Todas', image: todas});
      setCategories(data.categories);
      setStores(data.shopkeepers);
      setStoresFiltered(data.shopkeepers);
      await getBanners(data.shopkeepers);
      
    } catch(err) {
      console.log(err);
      console.log(err.response);
      const qrcode = getParamFromUrl('qrcode');
      if(qrcode) {
        window.location.href= `/?qrcode=${qrcode}`
        console.log(qrcode)
      }
    }
  }

  const filterByCategory = (index) => {
    setActiveCategorie(index)
    if (index === 0) {
      getBanners(stores)
      return setStoresFiltered(stores);
    }
    getBanners(stores.filter(({ id_category }) => id_category == categories[index].id))
    return setStoresFiltered(
      stores.filter(({ id_category }) => id_category == categories[index].id)
    )
  }

  const responsiveBebida = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 80
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 80
    }
  };

  const searchStore = (e) => {
    e.preventDefault();
    return setStoresFiltered(
      stores.filter((item)=> item.nome_loja.toLowerCase().match(search.toLocaleLowerCase()))
    )
  }

  const goToDetails = async (item) => {
    if(item.status_funcionamento === false) {
      return false
    }
    localStorage.setItem('store', JSON.stringify(item));
    localStorage.setItem('shopkeeper', item.id_loja);
    // history.push(`/loja/detalhes?qrcode=${qrcode}&id_mv_shopkeepers=${item.id_mv_shopkeepers}`)
    window.location.href= `/loja/detalhes?qrcode=${qrcode}&id_mv_shopkeepers=${item.id_mv_shopkeepers}`;
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      <div className="menuSearch">
        <form onSubmit={searchStore}>
          <input type="search" placeholder="Digite aqui..." onChange={(e) => setSearch(e.target.value)} />
          <button type="submit">
            <img src={Search} alt="busca" />
          </button>
        </form>
      </div>  
      <div className="homeArea">
        <div className="homeTitle">
          Categorias
        </div>
        <div className="homeAreaCategoriasNew">
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={false}
            showDots={false}
            responsive={responsiveCategorie}
            ssr={true} // means to render carousel on server-side.
            partialVisible={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 200ms ease-in-out"
            transitionDuration={400}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={"mobile"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-space"
          >
            
            {categories.map((item, index) => (
              <div key={index}>
                <div 
                  onClick={() => filterByCategory(index)} 
                  className={activeCategorie === index ? 'active' : ''}>
                  <div className="imagem">
                    <img src={item.image ? item.image : semProdutos} alt="categorias" />
                  </div>
                  <div className="name">{item.name}</div>
                </div>

              </div>
            ))}
          </Carousel>
        </div>
        {banners.length > 0 ?(
        <div className="destaquesSlider">
          <div className="homeTitle">
            Destaque
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={false}
            showDots={false}
            responsive={responsiveBebida}
            ssr={true} // means to render carousel on server-side.
            partialVisible={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={"mobile"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-space"
          >
            {banners.map((item, index) => (
              <div key={index} onClick={() => gotoItem(item.shopkeeper_id)} className="relativeItem">
                <div className="imageBig">
                <div className="imageBigItem" style={{backgroundImage: "url(" + (item.image_link ? item.image_link : semProdutos) + ")"}}></div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        ):('')}
        <div className="lojasArea">
          <div className="homeTitle">
            Lojas
          </div>
          <div className="lojasAreaList">
            <ul>
              {/* className="disabled" */}
              {storesFiltered.map((item, index) => (
                <li className={item.status_funcionamento ? '' : 'disabled'} key={index} onClick={() => goToDetails(item)}>
                  <div className="imageBox">
                    <div className={item.logotipo ? 'image' : 'imageCover'} style={{backgroundImage: "url(" + (item.logotipo ? item.logotipo : semProdutos) + ")"}}>
                      {item.status_funcionamento ? (
                      ''
                      ) : (
                        item.horario !== '' ? (
                          <div className="status_loja">
                            {item.horario}
                          </div>
                        ) : (
                          <div className="status_loja">
                            Fechado
                          </div>
                        )
                        )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
