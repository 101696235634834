import React, { useState, useEffect, useRef } from 'react';
import QrReader from 'react-qr-reader';
// import { createHashHistory } from "history";
// import { useHistory } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import introOne from '../../resources/images/inicial.png';
import introTwo from '../../resources/images/secundaria.png';
import logo from '../../resources/images/logotipo-preto.png';
import logoBranco from '../../resources/images/logotipo-branco.png';
import carregamento from '../../resources/images/loading_white.gif';
import { CarouselProvider, Slider, Slide, ButtonNext, DotGroup } from 'pure-react-carousel';
import axios from 'axios';
export default function Qrcode() {
  const [loading, setLoading] = useState(true);
  const [welcome, setWelcome] = useState(false);
  const [splash, setSplash] = useState(true);
  const [step, setStep] = useState(0);

  const refScan = useRef();
  const { current } = refScan;
  // const history = useHistory();
  // const [value, setValue] = useState(null);
  // const history = createHashHistory();

  const increaseStep = () => {
    setStep(step + 1)
  }

  const handleError = err => {
    console.error(err)
  }
  const handleScan = async data => {
    if (data) {
      try {
        let qr = data.split('=');
        let qrcodeUrl = qr[1];
        if (!qr[1]) {
          qrcodeUrl = data;
        }
        let retorno = await axios.get('webapp/list-products?qrcode=' + qrcodeUrl);
        let log = await axios.get('webapp/qrcode?qrcode=' + qrcodeUrl);
        console.log(log);
        if (retorno.data.type === 'mv') {
          localStorage.setItem('logo_mv', retorno.data.mv.image);
          localStorage.setItem('code_qr', qrcodeUrl);
          localStorage.setItem('mv', retorno.data.mv.id);
          if (current) {
            current.stopCamera();
          }
          window.location.href = `/home?qrcode=${qrcodeUrl}`;
          // history.push(`/home?qrcode=${qrcodeUrl}`);
        }
        if (retorno.data.type === 'shopkeeper') {
          localStorage.setItem('mv_shopkeeper', retorno.data.shopkeeper.id_mv_shopkeepers);
          localStorage.setItem('shopkeeper', retorno.data.shopkeeper.id);
          localStorage.setItem('code_qr', qrcodeUrl);
          localStorage.setItem('store', JSON.stringify(retorno.data.shopkeeper));
          localStorage.setItem('noback', '1');
          if (current) {
            current.stopCamera();
          }
          window.location.href = `/loja/detalhes?qrcode=${qrcodeUrl}&id_mv_shopkeepers=${retorno.data.shopkeeper.id_mv_shopkeepers}`;
          // history.push(`/loja/detalhes?qrcode=${qrcodeUrl}&id_mv_shopkeepers=${retorno.data.shopkeeper.id_mv_shopkeepers}`);
        }
      } catch (err) {
        console.log(err);
        console.log(err.response.data);
        alert(err.response.data);
        setLoading(false);
        // alert('Ops, houve um problema na leitura do QrCode');
      }
    }
  }

  const goScan = async () => {
    localStorage.setItem('welcome', '1');
    setWelcome(true);
    const param = getParamFromUrl('qrcode');
    if (param) {
      setLoading(true);
      handleScan(param);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }

  const getParamFromUrl = (param = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param)
  };
  useEffect(
    () => {
      // localStorage.clear();
      // localStorage.removeItem('mv_shopkeeper');
      // localStorage.removeItem('logo_mv');
      // localStorage.removeItem('code_qr');
      // localStorage.removeItem('store');
      // localStorage.removeItem('shopkeeper');

      // localStorage.removeItem('id_carrinho');
      // localStorage.removeItem('store');
      // localStorage.removeItem('code_qr');
      // localStorage.removeItem('pedido_id');

      localStorage.removeItem('direcionar_carrinho');
      localStorage.removeItem('noback');
      if (localStorage.getItem('welcome')) {
        setWelcome(true)
        const param = getParamFromUrl('qrcode');
        if (param) {
          setLoading(true);
          handleScan(param);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
      setTimeout(() => {
        setSplash(false);
      }, 800);
    },
    []
  );

  if (splash) {
    return (
      <div className="splashScreen">
        <img src={logoBranco} alt="Logo" />
      </div>
    )
  }

  if (loading) {
    return (
      <div className="content detalheProduto">
        <div className="loading_itens">
          <img src={carregamento} alt="loading" className="loadingGif" />
        </div>
      </div>
    )
  }

  if (welcome) {
    return (
      <div className="content qrcode">
        <div className="title uppercase">
          Escaneie e compre
        </div>
        <QrReader
          ref={refScan}
          className="qrcodeReader"
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }} />
        <div className="version">v 1.1.15</div>
      </div>
    )
  }

  if (step === 0) {
    return (
      <div className="sliderArea">
        <div className="centerSlider">
          <img src={introOne} alt="intro" />
          <div className="title">
            Seja bem-vindo(a) !!
          </div>
          <div className="text">
            Esse é seu primeiro contato com o Menu Virtual. Será uma experiência incrível!
          </div>
          <div onClick={() => increaseStep()} className="buttonGo" >Próximo</div>
        </div>
      </div>
    )
  }

  if (step === 1) {
    return (
      <div className="sliderArea">
        <div className="centerSlider">
          <img src={introOne} alt="intro" />
          <div className="title">
            Escolha seus produtos
          </div>
          <div className="text">
            Adicione os produtos no carrinho o que você deseja consumir
          </div>
          <div onClick={() => increaseStep()} className="buttonGo" >Próximo</div>
        </div>
      </div>
    )
  }

  if (step === 2) {
    return (
      <div className="sliderArea">
        <div className="centerSlider">
          <img src={introOne} alt="intro" />
          <div className="title">
            Siga a compra
          </div>
          <div className="text">
            Depois de escolher seu pedido, prossiga para o pagamento
          </div>
          <div onClick={() => increaseStep()} className="buttonGo" >Próximo</div>
        </div>
      </div>
    )
  }

  return (
    <div className="sliderArea">
      <div className="centerSlider">
        <img src={introOne} alt="intro" />
        <div className="title">
          Aproveite a comodidade
        </div>
        <div className="text">
          Caso não tenha uma conta, crie rapidamente e faça seus pedidos
        </div>
        <div onClick={() => goScan()} className="buttonGo" >Começar</div>
      </div>
    </div>
  )
}
