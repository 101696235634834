import React, { useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import MaskedInput from 'react-text-mask';

export default function NovaSenha() {
  const formLogin = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [newpassword, setNewPassword] = useState({
    pasword: '',
    paswordRepeat: '',
  });

  const send = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      let info = JSON.parse(localStorage.getItem('novosDados'));
      
      if(newpassword.password !== newpassword.passwordRepeat) {
        alert('O campo senha e repita a senha devem ser iguais!')
        setLoading(false);
        return false
      }
      let dados = {
        phone: info.phone,
        verified_code: info.verified_code,
        new_password: newpassword.password
      }
      const {data} = await axios.post('customers/password-new', dados)
      alert(data);
      localStorage.removeItem('novosDados');
      history.push("/login");
      setLoading(false);
    } catch (err) {
      alert('Ops, houve um erro ao atualizar sua senha! '+err);
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="content login">
       <div className="loginTitle">
        Nova senha
      </div>
      <div className="loginText">
        Preencha abaixo a nova senha
      </div>
      <form ref={formLogin} onSubmit={send}>
        <div className="ui-input-title">
          Nova senha
        </div>
        <input
          value={newpassword.password}
          onChange={(e) => setNewPassword({ ...newpassword, password: e.target.value })}
          className="ui-input" 
          placeholder="Senha" 
          type="password" 
          required 
        />
        <div className="ui-input-title">
          Repita sua nova senha
        </div>
        <input
          value={newpassword.passwordRepeat}
          onChange={(e) => setNewPassword({ ...newpassword, passwordRepeat: e.target.value })}
          className="ui-input" 
          placeholder="Repita a senha" 
          type="password" 
          required 
        />
        <div className="buttonArea right_button">
          <button type="submit" disabled={loading}>
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}
