import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './app.global.scss';
import Menu from './pages/menu';
import Qrcode from './pages/qrcode';
import Intro from './pages/intro';
import Home from './pages/home';
import BackQrcode from './pages/home/backqrcode';
import NotiticationNotation from './pages/notifications/button';
import Notitications from './pages/notifications/index';
import PedidosLista from './pages/pedidos/index';
import PedidosInterna from './pages/pedidos/intern';
import LojaHome from './pages/lojas/index';
import LojaLanches from './pages/lojas/listLanche';
import DestaquesList from './pages/lojas/destaquesList';
import DetailProduct from './pages/lojas/detailProduct';
import Carrinho from './pages/carrinho';
import Cupom from './pages/carrinho/cupom';
import Login from './pages/login';
import EsqueciSenha from './pages/login/esqueciSenha';
import EsqueciSenhaToken from './pages/login/esqueciSenhaToken';
import NovaSenha from './pages/login/novaSenha';
import Cadastro from './pages/cadastro';
import Token from './pages/login/token';
import Card from './pages/card';
import Success from './pages/card/paymentSuccess';
import SuccessBox from './pages/card/paymentSuccessBox';
import PixAguardar from './pages/card/pixAguardar';
import CardAdd from './pages/card/cardAdd';
import UserCode from './pages/user/qrcode';
import Perfil from './pages/perfil';
import PerfilEdit from './pages/perfil/editar';
import PerfilCard from './pages/perfil/cartao';
import PerfilCardAdd from './pages/perfil/cartaoAdd';
import axios from 'axios';

import logo from './resources/images/logotipo-preto.png';
import remove from './resources/images/remove.png';

export default function App() {
  const [logado, setLogado] = useState(false);
  const [notification, setNotification] = useState([]);
  const ref = useRef();
  const loadContent = async () => {
    if(localStorage.getItem('user') !== '{}') {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        axios.defaults.headers.common['Authorization'] = user.api_token;
        setLogado(user);
        window.OneSignal.push(function() {
          window.OneSignal.registerForPushNotifications();
          window.OneSignal.sendTag("customer", user.id);
        });
      }
    } else {
      // OneSignal.push(function() {
      //   OneSignal.registerForPushNotifications();
      //   OneSignal.deleteTag("customer");
      // });
      setLogado(false);
    }
  };

  const getNotification = async () => {
    try {
      let {data} = await axios.get('notifications?unread=0');
      setNotification(data);
    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const removeAllNotifications = async () => {
    try {
      setNotification([])
      let {data} = await axios.post('notifications/update');
    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  const removeNavigation = async (item, route) => {
    let update = {
      notification: item
    }
    let {data} = await axios.post('notifications/update', update);
    window.location.href = '/'+route
  }

  const removeNotification = async (item, index) => {
    try {
      let notificacao = document.getElementById('notification_'+item)
      let array = notification;
      array[index].show = true;
      setNotification([...array]);
      
      let update = {
        notification: item
      }
      let {data} = await axios.post('notifications/update', update);

    } catch (err) {
      alert('Ops! '+err);
      console.log(err);
    }
  }

  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
    // script.async = true;
    // document.body.appendChild(script);

    loadContent();
    setInterval(() => {
      if(localStorage.getItem('user') !== '{}' && localStorage.getItem('user') !== null) {
        getNotification()
      }
    }, 15000);
  }, []);
  return (
    <Router>
      <div className="App">
        <div className="menuArea">
          <Menu />
        </div>
        <div className="contentArea">
          <Switch>
            {logado && (
            <Route path="/perfil/cartao/adicionar">
              <PerfilCardAdd />
            </Route>
            )}
            {logado && (
            <Route path="/perfil/cartao">
              <PerfilCard />
            </Route>
            )}
            {logado && (
            <Route path="/perfil/editar">
              <PerfilEdit />
            </Route>
            )}
            {logado && (
            <Route path="/perfil">
              <Perfil refreshUser={loadContent} />
            </Route>
            )}
            {logado && (
            <Route path="/user/qrcode">
              <UserCode />
            </Route>
            )}
            {logado && (
            <Route path="/pagamento/sucesso">
              <Success />
            </Route>
            )}
            {logado && (
            <Route path="/pagamento/pix-aguardar">
              <PixAguardar />
            </Route>
            )}
            {logado && (
            <Route path="/pagamento/sucesso-caixa">
              <SuccessBox />
            </Route>
            )}
            {logado && (
            <Route path="/login/cartao/add">
              <CardAdd />
            </Route>
            )}
            {logado && (
            <Route path="/carrinho/cartao">
              <Card />
            </Route>
            )}
            <Route path="/cadastro/token">
              <Token />
            </Route>
            <Route path="/carrinho/cupom">
              <Cupom />
            </Route>
            {logado && (
            <Route path="/carrinho">
              <Carrinho />
            </Route>
            )}
            <Route path="/login">
              <Login refreshUser={loadContent} />
            </Route>
            <Route path="/esqueci-a-senha/nova-senha">
              <NovaSenha />
            </Route>
            <Route path="/esqueci-a-senha/token">
              <EsqueciSenhaToken />
            </Route>
            <Route path="/esqueci-a-senha">
              <EsqueciSenha />
            </Route>
            <Route path="/cadastro">
              <Cadastro />
            </Route>
            <Route path="/loja/add">
              <DetailProduct />
            </Route>
            <Route path="/loja/destaques">
              <DestaquesList />
            </Route>
            <Route path="/loja/produtos">
              <LojaLanches />
            </Route>
            <Route path="/loja/detalhes">
              <LojaHome />
            </Route>
            {logado && (
            <Route path="/pedidos/interna">
              <PedidosInterna />
            </Route>
            )}
            {logado && (
            <Route path="/pedidos">
              <PedidosLista />
            </Route>
            )}
            {logado && (
            <Route path="/notificacoes">
              <Notitications />
            </Route>
            )}
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/intro">
              <Intro />
            </Route>
            <Route path="/">
              <Qrcode />
            </Route>
          </Switch>
        </div>
        <div className="notificacoes">
          <NotiticationNotation />
        </div>
        <div className="backQrcode">
          <BackQrcode />
        </div>
        <div className="notifications_fixed">
          <ul>
          {notification.map((item, index) => (
            <li key={index} className={item.show == true ? 'ocultar': ''}>
              <div className="close" onClick={() => removeNotification(item.id, index)}>x</div>
              <div className="areaNotification" onClick={() => removeNavigation(item.id, item.route)}>
                {item.image ? (
                  <div className="imageArea" onClick={() => window.location.href = '/'+item.route}>
                    <div className="image" style={{backgroundImage: "url(" + item.image +")"}}></div>
                  </div>
                ) : (
                  <div className="imageArea" onClick={() => window.location.href = '/'+item.route}>
                    <div className="image" style={{backgroundImage: "url(" + logo +")"}}></div>
                  </div>
                )}
                <div className="content_area">
                  <div className="time">{item.data}</div>
                  <div className="text" dangerouslySetInnerHTML={{ __html: item.message }}>
                  </div>
                </div>
              </div>
            </li>
          ))}
          </ul>
          {notification && notification.length > 2 ? (
          <div className="notifications_remove" onClick={() => removeAllNotifications()}>
            <img src={remove} alt="Remover" />
            Limpar notificações
          </div>
          ):('')}
        </div>
      </div>
    </Router>
  );
}